import React, { useState } from 'react'
import './SearchUser.sass'
import { createForm } from '../../../../../utils/newforms/createForm'
import getFieldRenderObject from '../../../../../utils/newforms/render/getFieldRenderObject'
import FormRender from '../../../../../utils/newforms/render/FormRender'
import getCollection from '../../../../../utils/db/getCollection'
import { useEffect } from 'react'
import { db } from '../../../../../config/firebase'
import { isEmpty } from 'lodash'
import Spinner from '../../../../../ui/Spinner/Spinner'
import Icon from '../../../../../ui/Icon/Icon'
import { ClientRegInsert } from '../../../../Clients/ClientsManager/ClientForm/ClientForm'
import Button from '../../../../../ui/Button/Button'

function SearchUser({ pinnedUser, setPinnedUser }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: new UserInputForm(),
    })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [users, setUsers] = useState(null)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [showRegNew, setShowRegNew] = useState(null)

  useEffect(() => {
    let dbUsersListener = () => null
    dbUsersListener = db.collection('users').onSnapshot((snap) => {
      const usersArr = []
      snap.docs.every((d) => usersArr.push({ ...d.data(), userId: d.id }))
      setUsers(usersArr)
    })

    return () => {
      dbUsersListener()
    }
  }, [])

  useEffect(() => {
    const searchWord = form.user.value.toLowerCase()
    if (searchWord.length > 3 && !isEmpty(users)) {
      setFilteredUsers(
        users.filter((u) => {
          if (
            u.lastName.toLowerCase().includes(searchWord) ||
            u.name.toLowerCase().includes(searchWord) ||
            `${u.phone}`.includes(searchWord) ||
            u.email.toLowerCase().includes(searchWord)
          ) {
            return true
          }
          return false
        })
      )
    }
  }, [form.user.value])

  return (
    <div className="SearchUser">
      <p className="Title">Поиск существующего пользователя</p>
      {!isEmpty(users) ? (
        <>
          <FormRender
            sections={[
              {
                fields: ['user'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          {form.user.value.length > 3 && isEmpty(pinnedUser) && (
            <div className="SearchData-Container">
              {isEmpty(filteredUsers) ? (
                <>
                  {!showRegNew && (
                    <>
                      <p className="EmptyResult">Пользователь не найден</p>
                      <div className="RegNewButton">
                        <Button
                          title="Регистрация нового пользователя"
                          theme="solid"
                          fill="accent"
                          iconPosition="right"
                          size={40}
                          onClick={() => setShowRegNew(true)}
                        />
                      </div>
                    </>
                  )}
                  {showRegNew && (
                    <ClientRegInsert onRegComplete={setPinnedUser} />
                  )}
                </>
              ) : (
                <div className="SearchResults">
                  {filteredUsers.map((u) => (
                    <div
                      className="SearchResult-Tile"
                      onClick={() => setPinnedUser(u)}
                    >
                      <p className="UserName">
                        {u.lastName} {u.name}
                      </p>
                      <p className="Contacts">
                        {u.email}, {u.phone}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {!isEmpty(pinnedUser) && (
            <div className="PinnedUser">
              <div className="Data">
                <p className="UserName">
                  {pinnedUser.lastName} {pinnedUser.name}
                </p>
                <p className="Contacts">
                  {pinnedUser.email}, {pinnedUser.phone}
                </p>
              </div>
              <div
                className="Icon-Container"
                onClick={() => setPinnedUser(null)}
              >
                <Icon name="times" />
              </div>
            </div>
          )}
        </>
      ) : (
        <Spinner type="module" />
      )}
    </div>
  )
}

class UserInputForm {
  constructor() {
    this.user = {
      field: {
        fieldId: 'user',
        fieldType: 'input',
        inputType: 'text',
        label: 'Фио пациента, номер телефона, другие данные',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default SearchUser
