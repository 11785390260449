import React, { useState, useEffect } from 'react'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../../utils/newforms/createForm'
import getDoc from '../../../utils/db/getDoc'
import FormRender from '../../../utils/newforms/render/FormRender'
import PopUp from '../../../ui/PopUp/PopUp'
import CategoryForm from './categoryObject'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import { toNumber, omit } from 'lodash'
import uploadIcon from './uploadIcon'

function CategoryManager({ activeCityId, categoryId, nextPos, onClose }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  useEffect(() => {
    getDoc({
      path: `settings/price/categories`,
      docId: categoryId,
      docIdName: 'categoryId',
    }).then((data) => {
      console.log(data)
      setForm(
        createForm({
          formData: data
            ? { ...data, pos: `${data.pos}` }
            : { cityId: activeCityId },
          formPattern: new CategoryForm(nextPos),
        })
      )
    })
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля',
        closeAfter: 3000,
      })
    }
    setIsLoading(true)
    const data = getFormValues({ form })
    const values = {
      ...omit(data, ['icon']),
      pos: toNumber(data.pos),
    }

    let dbFunc
    if (categoryId) {
      dbFunc = updateDoc({
        path: `settings/price/categories`,
        docId: categoryId,
        data: values,
      })
    } else {
      dbFunc = addDoc({ path: `settings/price/categories`, data: values })
    }
    dbFunc
      .then((docId) =>
        uploadIcon({
          form,
          setForm,
          docPath: 'settings/price/categories',
          docId,
        })
      )
      .then(() => window.location.reload())
      .catch((error) => {
        console.log(
          '🚀 ~ file: CategoryManager.jsx:74 ~ onSubmit ~ error:',
          error
        )
        // logError({ error })
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <PopUp
      show
      close={onClose}
      title={categoryId ? 'Редактировать категорию' : 'Создать категорию'}
      submitButtons
      onSubmit={onSubmit}
      onCancel={onClose}
      isLoading={isLoading}
    >
      <div className="CategoryPrice-PopUp-Container">
        {statusMessage.show && (
          <StatusMessage
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <FormRender
          sections={[
            {
              fields: [
                'title',
                'pos',
                'fixedCheckout',
                'givesDoc',
                'groupTag',
                'icon',
              ],
            },
          ]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
      </div>
    </PopUp>
  )
}

export default CategoryManager
