import { isEmpty } from 'lodash'
import promiseAllValues from '../../../../../utils/promiseAllValues'
import getCollection from '../../../../../utils/db/getCollection'

export default function checkUserByPhoneAndEmail({ email, phone }) {
  return promiseAllValues({
    // byPhoneAndEmail: getCollection({
    //   path: 'users',
    //   docIdName: 'userId',
    //   whereQueries: [
    //     { fieldPath: 'email', op: '==', value: email },
    //     { fieldPath: 'phone', op: '==', value: phone },
    //   ],
    // }),
    // byPhone: getCollection({
    //   path: 'users',
    //   docIdName: 'userId',
    //   whereQueries: [{ fieldPath: 'phone', op: '==', value: phone }],
    // }),
    byEmail: getCollection({
      path: 'users',
      docIdName: 'userId',
      whereQueries: [{ fieldPath: 'email', op: '==', value: email }],
    }),
  }).then((exist) => {
    // if (!isEmpty(exist.byPhoneAndEmail))
    //   return Promise.reject('existByPhoneAndEmail')
    // if (!isEmpty(exist.byPhone)) return Promise.reject('existByPhone')
    if (!isEmpty(exist.byEmail)) return Promise.reject('existByEmail')
    return true
  })
}
