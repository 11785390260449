import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import DoctorsManager from './DoctorsManager/DoctorsManager'
import DoctorsList from './DoctorsList/DoctorsList'

function Routes() {
  return (
    <>
      {/* <Route exact path="/">
        <Redirect to="/tours" />
      </Route> */}
      <Route path="/doctors" exact component={DoctorsList} />
      <Route path="/doctors/add" exact component={DoctorsManager} />
      <Route path="/doctors/edit/:id" exact component={DoctorsManager} />
    </>
  )
}

export default Routes
