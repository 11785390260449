import React, { useState } from 'react'
import './EventManager.sass'
import PopUp from '../../../../ui/PopUp/PopUp'
import { createForm } from '../../../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../../../ui/StatusMessage/StatusMessage'
import getFieldRenderObject from '../../../../utils/newforms/render/getFieldRenderObject'
import InfoLine from '../../../../ui/InfoLine/InfoLine'
import FormRender from '../../../../utils/newforms/render/FormRender'
import Button from '../../../../ui/Button/Button'
import isFormValid from '../../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../../utils/newforms/getFormValues'
import { addMinutes, format, isBefore, setHours, setMinutes } from 'date-fns'
import { ru } from 'date-fns/locale'
import { isEqual, isNumber, omit, toNumber } from 'lodash'
import addDoc from '../../../../utils/db/addDoc'
import { formatDateAsHHMM } from '../../../../utils/date/dateFormat'

function EventManager({ cityId, date, doctor, categories, onClose }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: new EventForm({
        categories,
        date,
        doctorId: doctor.doctorId,
        cityId,
      }),
    })
  )
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }

    setIsLoading(true)

    const data = getFormValues({ form })
    const timeStartArray = data.timeStart.split(':')
    const timeEndArray = data.timeEnd.split(':')
    const dateStart = setMinutes(
      setHours(date, toNumber(timeStartArray[0])),
      toNumber(timeStartArray[1])
    )
    const dateEnd = setMinutes(
      setHours(date, toNumber(timeEndArray[0])),
      toNumber(timeEndArray[1])
    )

    const values = {
      ...omit(data, ['setInterval', 'interval']),
      dateStart,
      dateEnd,
    }

    let taskRef

    if (data.setInterval && !isNumber(data.interval)) {
      const tilesArray = []
      let nextDateStart = dateStart
      let nextDateEnd = addMinutes(dateStart, data.interval)

      while (isBefore(nextDateEnd, dateEnd) || isEqual(nextDateEnd, dateEnd)) {
        tilesArray.push(
          addDoc({
            path: 'schedule',
            data: {
              ...data,
              dateStart: nextDateStart,
              dateEnd: nextDateEnd,
              timeStart: format(nextDateStart, 'HH:mm', { locale: ru }),
              timeEnd: format(nextDateEnd, 'HH:mm', { locale: ru }),
            },
          })
        )
        nextDateStart = addMinutes(nextDateStart, data.interval)
        nextDateEnd = addMinutes(nextDateEnd, data.interval)
      }

      taskRef = Promise.all(tilesArray)
    } else {
      taskRef = addDoc({ path: 'schedule', data: values })
    }

    taskRef
      .then((docId) => onClose())
      .catch((error) => {
        console.log(
          '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <PopUp
      show
      title={'Добавить ячейку записи'}
      className="EventManager"
      close={onClose}
    >
      <div className="EventManager-Content">
        <div className="StatickData">
          <InfoLine
            title="Дата"
            content={format(date, 'cccccc, dd.MM.yy', { locale: ru })}
          />
          <InfoLine title="Врач" content={doctor.title} />
        </div>
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <div className="TimeFields">
          <FormRender
            sections={[
              {
                fields: [
                  'timeStart',
                  'timeEnd',
                  'setInterval',
                  'interval',
                  'eventType',
                  'eventLimit',
                ],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div>
        <FormRender
          sections={[
            {
              fields: ['specialization'],
            },
          ]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
        <div className="Buttons">
          <Button
            title="Сохранить"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрыть"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            onClick={onClose}
          />
        </div>
      </div>
    </PopUp>
  )
}

class EventForm {
  constructor({ categories, date, doctorId, cityId }) {
    this.date = {
      field: {
        fieldId: 'date',
        value: date,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.dateStart = {
      field: {
        fieldId: 'dateStart',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.timeStart = {
      field: {
        fieldId: 'timeStart',
        fieldType: 'input',
        inputType: 'text',
        label: 'Время начало',
        mask: 'time',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.dateEnd = {
      field: {
        fieldId: 'dateEnd',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.timeEnd = {
      field: {
        fieldId: 'timeEnd',
        fieldType: 'input',
        inputType: 'text',
        label: 'Время конец',
        mask: 'time',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.setInterval = {
      field: {
        fieldId: 'setInterval',
        fieldType: 'checkbox',
        inputType: 'text',
        label: 'Создать запись с интервалом',
      },
      render: getFieldRenderObject(),
    }
    this.interval = {
      field: {
        fieldId: 'interval',
        fieldType: 'input',
        inputType: 'number',
        label: 'Интервал в минутах',
      },
      render: getFieldRenderObject(),
    }
    this.eventType = {
      field: {
        fieldId: 'eventType',
        fieldType: 'select',
        getOptions: [
          { label: 'Индивидуальная', value: 'individual' },
          { label: 'Групповая', value: 'group' },
        ],
        value: 'individual',
        label: 'Тип записи',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.eventLimit = {
      field: {
        fieldId: 'eventLimit',
        fieldType: 'input',
        inputType: 'number',
        label: 'Лимит группы',
      },
      render: getFieldRenderObject(),
    }
    this.specialization = {
      field: {
        fieldId: 'specialization',
        fieldType: 'select',
        getOptions: categories,
        label: 'Специализация врача',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.doctorId = {
      field: { fieldId: 'doctorId', value: doctorId },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.cityId = {
      field: { fieldId: 'cityId', value: cityId },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.user = {
      field: { fieldId: 'user', value: null },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.members = {
      field: { fieldId: 'members', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.comment = {
      field: { fieldId: 'comment', value: '' },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.status = {
      field: { fieldId: 'status', value: 'empty' },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.payment = {
      field: { fieldId: 'payment', value: null },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: { fieldId: 'isDeleted', value: false },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default EventManager
