import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'

class ServiceForm {
  constructor(nextPos) {
    this.categoryId = {
      field: { fieldId: 'categoryId' },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название услуги',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.price = {
      field: {
        fieldId: 'price',
        fieldType: 'input',
        inputType: 'text',
        label: 'Стоимость',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.schedule = {
      field: {
        fieldId: 'schedule',
        fieldType: 'input',
        inputType: 'text',
        label: 'Срок выполнения',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.duration = {
      field: {
        fieldId: 'duration',
        fieldType: 'input',
        inputType: 'text',
        label: 'Продолжительность',
        required: false,
      },
      render: getFieldRenderObject(),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиция',
        required: true,
        value: nextPos,
      },
      render: getFieldRenderObject(),
    }
    this.isDeleted = {
      field: {
        fieldId: 'isDeleted',
        value: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default ServiceForm
