import React from 'react'
import './SpecializationModule.sass'
import CityChooser from '../../../../ui/CityChooser/CityChooser'
import { useState } from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../../../App'
import { useEffect } from 'react'
import promiseAllValues from '../../../../utils/promiseAllValues'
import getCollection from '../../../../utils/db/getCollection'
import { cloneDeep, includes, isEmpty } from 'lodash'
import Icon from '../../../../ui/Icon/Icon'

function SpecializationModule({
  doctorCats,
  setDoctorCats,
  doctorServices,
  setDoctorServices,
}) {
  const { cities } = useContext(AuthContext)
  const [activeCityId, setActiveCityId] = useState(cities[0].id)
  const [categories, setCategories] = useState(null)
  const [services, setServices] = useState(null)

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
        orderBy: { fieldPath: 'pos', orderDir: 'asc' },
      }),
      services: getCollection({
        path: 'settings/price/services',
        docIdName: 'serviceId',
        orderBy: { fieldPath: 'pos', orderDir: 'asc' },
      }),
    }).then((result) => {
      setCategories(result.categories)
      setServices(result.services)
    })
  }, [])

  const setCategorySpecialization = (id) => {
    const temp = cloneDeep(doctorCats)

    if (includes(doctorCats, id)) setDoctorCats(temp.filter((c) => c !== id))
    else {
      temp.push(id)
      setDoctorCats(temp)
    }
  }

  const setServiceSpecialization = (id) => {
    const temp = cloneDeep(doctorServices)

    if (includes(doctorServices, id))
      setDoctorServices(temp.filter((s) => s !== id))
    else {
      temp.push(id)
      setDoctorServices(temp)
    }
  }

  return (
    <div className="SpecializationModule">
      <CityChooser
        activeCityId={activeCityId}
        setActiveCityId={setActiveCityId}
      />
      <div className="SpecializationModule-Content">
        {!isEmpty(categories) &&
          !isEmpty(services) &&
          categories
            .filter((c) => c.cityId === activeCityId)
            .map((c) => (
              <div>
                <div
                  className="CategoryTitle"
                  onClick={() =>
                    c.fullService
                      ? setCategorySpecialization(c.categoryId)
                      : null
                  }
                >
                  <span
                    className={`Chooser ${
                      (includes(doctorCats, c.categoryId) || !c.fullService) &&
                      'Chooser_theme_active'
                    }`}
                  >
                    <Icon
                      name={c.fullService ? 'check' : 'minus'}
                      weight="regular"
                    />
                  </span>
                  {c.title}
                </div>
                {!c.fullService && (
                  <div className="ServicesContent">
                    {services
                      .filter((s) => s.categoryId === c.categoryId)
                      .map((s) => (
                        <div
                          className="ServiceTitle"
                          onClick={() => setServiceSpecialization(s.serviceId)}
                        >
                          <span
                            className={`Chooser ${
                              includes(doctorServices, s.serviceId) &&
                              'Chooser_theme_active'
                            }`}
                          >
                            <Icon name="check" weight="regular" />
                          </span>
                          {s.title}
                        </div>
                      ))}
                  </div>
                )}
              </div>
            ))}
      </div>
    </div>
  )
}

export default SpecializationModule
