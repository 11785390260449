import React, { useState, useEffect } from 'react'
import getDoc from '../../../../utils/db/getDoc'
import { isEmpty } from 'lodash'
import DeletePopUp from '../../../../ui/PopUp/DeletePopUp/DeletePopUp'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { updateDoc } from '../../../../utils/db/updateDoc'
import { db } from '../../../../config/firebase'

const DeleteEventPopUp = ({ tileId, eventType, closePopUp }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState(null)

  const onDelete = async () => {
    setIsLoading(true)
    if (eventType === 'deleteUser') {
      updateDoc({
        path: 'schedule',
        docId: tileId,
        data: {
          payment: null,
          user: null,
          status: 'empty',
        },
      }).then(() => closePopUp())
    } else
      db.collection('schedule')
        .doc(tileId)
        .delete()
        .then(() => closePopUp())
  }

  useEffect(() => {
    getDoc({ path: 'schedule', docId: tileId }).then((res) => setData(res))
  }, [])

  return !isEmpty(data) ? (
    <DeletePopUp
      title={
        eventType === 'deleteUser'
          ? 'Отмена записи пользователя'
          : 'Удаление записи'
      }
      warningMessage={
        <>
          Вы уверены, что хотите{' '}
          {eventType === 'deleteUser' ? (
            <>
              отменить запись для пользователя{' '}
              {<span className="Bolder">{data.user.userName}</span>}
            </>
          ) : (
            'удалить запись'
          )}{' '}
          на указанную дату:{' '}
          <span className="Bolder">
            {format(data.date.seconds * 1000, 'cccccc, dd.MM.yy', {
              locale: ru,
            })}{' '}
            с {data.timeStart} до {data.timeEnd}?
          </span>
        </>
      }
      fieldLabel="Чтобы удалить эту новость, введите ее идентификатор:"
      identifier={tileId}
      closePopUp={closePopUp}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  ) : null
}

export default DeleteEventPopUp
