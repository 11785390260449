import React, { useContext } from 'react'
import './Nav.sass'
import { NavLink } from 'react-router-dom'
import Icon from '../../../ui/Icon/Icon'
import { firebase } from '../../../config/firebase'
import { AuthContext } from '../../../App'
import ClientNavElement from '../ClientNavElement/ClientNavElement'

function Nav() {
  const { user, cities } = useContext(AuthContext)

  return (
    <div className="Nav">
      <div className="NavBody">
        {user.role === 'doctor' ? (
          <NavElement title="Запись" icon="calendar-alt" link="/" />
        ) : (
          <>
            <ClientNavElement />
            <NavElement title="Запись" icon="calendar-alt" link="/checkout" />
            <NavElement title="Новости" icon="newspaper" link="/news" />
            <NavElement title="Врачи" icon="user-md" link="/doctors" />
            <NavElement title="Прайс" icon="list" link="/price" />
            <NavElement title="PDF" icon="file-pdf" link="/pdf" />
          </>
        )}

        {/*
        <NavElement title="ЧаВо" icon="question" link="/faq" />

        {/* <NavElement title="ЧаВо" icon="question" link="/faq" />
        <NavElement title="Партн." icon="handshake-alt" link="/partners" />
        <NavElement title="Другое" icon="cog" link="/settings" />
        <NavElement title="QR code" icon="qrcode" link="/qrcode" /> */}
      </div>

      <NavElement
        title="Выход"
        icon="sign-out-alt"
        onClick={() => firebase.auth().signOut()}
      />
    </div>
  )
}

export function NavElement({ title, icon, link, onClick, counter = 0 }) {
  const content = (
    <>
      <div className="NavElement-Icon">
        {counter > 0 && <span className="Counter">{counter}</span>}
        <Icon name={icon} />
      </div>
      <p className="Title">{title}</p>
    </>
  )
  return onClick ? (
    <div className="NavElement" onClick={onClick}>
      {content}
    </div>
  ) : (
    <NavLink
      to={link}
      className="NavElement"
      activeClassName="NavElement-Active"
    >
      {content}
    </NavLink>
  )
}

export default Nav
