import React, { useEffect, useState } from 'react'
import { find, isArray, isEmpty } from 'lodash'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import DeleteNewsPopUp from './DeleteNewsPopUp'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import Icon from '../../../ui/Icon/Icon'
import { Img } from 'react-image'

function Newslist({ accountId, accountUser, ...router }) {
  const [isLoading, setIsLoading] = useState(false)
  const [news, setNews] = useState(null)
  const [showPopUp, setShowPopUp] = useState(null)

  useEffect(() => {
    db.collection(`/news`).onSnapshot((snap) => {
      const result = []
      snap.docs.forEach((n) => result.push({ newsId: n.id, ...n.data() }))
      const actualNews = result.filter((n) => n.isDeleted === false)
      setNews(actualNews)
    })
  }, [])

  return (
    <RouteComponent
      title="Новости"
      controlls={
        <>
          <Button
            title="Добавить новость"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/news/add"
            size={28}
          />
        </>
      }
    >
      <div className="DoctorsCatalog">
        {showPopUp && (
          <DeleteNewsPopUp
            newsId={showPopUp && showPopUp.newsId}
            closePopUp={() => setShowPopUp(null)}
          />
        )}
        {news ? (
          isEmpty(news) ? (
            <p className="EmptyText">Нет новостей</p>
          ) : (
            news.map((n, i) => (
              <NewsTile
                key={`news-${i}`}
                {...n}
                onEdit={() => router.history.push(`/news/${n.newsId}`)}
                onDelete={() =>
                  setShowPopUp({
                    title: n.title,
                    newsId: n.newsId,
                  })
                }
              />
            ))
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
  // return (
  //   <SceneContent
  //     title="Новости"
  //     buttons={[
  //       {
  //         type: 'default',
  //         icon: 'cloud-upload-alt',
  //         clicked: () => null,
  //       },
  //       {
  //         type: 'add',
  //         icon: 'plus',
  //         clicked: () => router.history.push(`${rootPath(accountId)}/settings/site/news/add`),
  //       },
  //     ]}
  //   >
  //     <>
  //
  //       <SceneTable
  //         editOnDoubleClick
  //         header={[['Дата создания', 110], ['Статус', 110], ['Новость'], ['Автор', 180], ['', 32]]}
  //         // skeleton={getLoadingSkeleton(15)}
  //         useCustomOptions
  //         body={
  //           isArray(news)
  //             ? news.map((n, i) => [
  //                 { id: n.newsId },
  //                 formatDateAsDayMontYearHHMM(n.created.at),
  //                 <div>{n.status === 'published' ? 'Опубликовано' : 'Черновик'}</div>,
  //                 <div>{n.title}</div>,
  //                 <UserNameWithAvatar
  //                   key={`displayed-data-${n.newsId}-${i}`}
  //                   avatarUrl={n.author.avatar.publicUrl}
  //                   avatarSize="26"
  //                   userName={`${n.author.lastName} ${n.author.name} ${n.author.middleName}`}
  //                 />,
  //                 // <div>
  //                 //   <p>{`${contact.lastName} ${contact.name} ${contact.middleName}`}</p>
  //                 //   <TableTagCloud tags={contact.tags} style={{ marginTop: '4px' }} />
  //                 // </div>,
  //                 // <span>{contact.organization}</span>,
  //                 // <span>test</span>,

  //                 {
  //                   options: [
  //                     {
  //                       return: 'docId',
  //                       type: 'edit',
  //                       icon: 'external-link',
  //                       label: 'Открыть',
  //                       clicked: (id) => router.history.push(`${rootPath(accountId)}/settings/site/news/${id}`),
  //                     },
  //                     {
  //                       return: 'docId',
  //                       type: 'delete',
  //                       icon: 'trash-alt',
  //                       label: 'Удалить',
  //                       clicked: (id) => setShowPopUp({ newsId: id }),
  //                     },
  //                   ],
  //                 },
  //               ])
  //             : []
  //         }
  //         isLoading={isLoading}
  //       />
  //     </>
  //   </SceneContent>
  // )
}

function NewsTile({
  title,
  specialization,
  updated,
  photos,
  onEdit,
  onDelete,
}) {
  return (
    <div className="DoctorTile" onDoubleClick={onEdit}>
      <div className="Avatar">
        {!isEmpty(photos) ? (
          <Img src={find(photos, ['isAvatar', true]).publicUrl} />
        ) : (
          <div className="DoctorAvatar">
            <Icon name="newspaper" />
          </div>
        )}
      </div>
      <div className="Content">
        <p className="Title">{title}</p>
      </div>
      <div className="Options">
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(Newslist)
