import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'

class CategoryForm {
  constructor(nextPos) {
    this.cityId = {
      field: {
        fieldId: 'cityId',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Название категории',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиция',
        required: true,
        value: nextPos,
      },
      render: getFieldRenderObject(),
    }
    this.icon = {
      field: {
        fieldId: 'icon',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Иконка',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
    this.groupTag = {
      field: {
        fieldId: 'groupTag',
        fieldType: 'input',
        inputType: 'text',
        label: 'Тег для медкомиссии',
      },
      render: getFieldRenderObject(),
    }
    this.fullService = {
      field: {
        fieldId: 'fullService',
        value: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.fixedCheckout = {
      field: {
        fieldId: 'fixedCheckout',
        fieldType: 'checkbox',
        inputType: 'text',
        label: 'Запись на предварительную консультацию',
      },
      render: getFieldRenderObject(),
    }
    this.givesDoc = {
      field: {
        fieldId: 'givesDoc',
        fieldType: 'checkbox',
        inputType: 'text',
        label: 'Загрузка документов',
      },
      render: getFieldRenderObject(),
    }
    this.specTitle = {
      field: {
        fieldId: 'specTitle',
        value: '',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: {
        fieldId: 'isDeleted',
        value: false,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default CategoryForm
