import React from 'react'
import './ClientsManager.sass'
import RouteComponent from '../../RouteComponent/RouteComponent'
import ClientForm from './ClientForm/ClientForm'
import ClientHistory from './ClientHistory/ClientHistory'

function ClientsManager() {
  return (
    <div className="ClientsManager">
      <RouteComponent title="Информация о клиенте">
        <ClientForm />
      </RouteComponent>
      <RouteComponent title="История событий">
        <ClientHistory />
      </RouteComponent>
    </div>
  )
}

export default ClientsManager
