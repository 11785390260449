import React, { useEffect } from 'react'
import { NavElement } from '../Nav/Nav'
import { useState } from 'react'
import { db } from '../../../config/firebase'

function ClientNavElement() {
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    let dbCounter = () => null

    dbCounter = db
      .collection('schedule')
      .onSnapshot((data) =>
        setCounter(
          data.docs.filter((d) => d.data().status === 'awaitResult' && true)
            .length
        )
      )
    return () => {
      dbCounter()
    }
  }, [])

  return (
    <NavElement
      title="Клиенты"
      icon="users"
      link="/clients"
      counter={counter}
    />
  )
}

export default ClientNavElement
