import { db, firebase } from '../../../../config/firebase'
import { updateDoc } from '../../../../utils/db/updateDoc'

const deleteDoctor = ({ doctorId }) =>
  updateDoc({ path: 'doctors', docId: doctorId, data: { isDeleted: true } })
// deleteFolderContents(`tours/${tourId}`)
//   .then(() => db.collection('tours').doc(tourId).delete())
//   .catch((e) => console.log(e))

// function deleteFolderContents(path) {
//   const ref = firebase.storage().ref(path)
//   return ref
//     .listAll()
//     .then((dir) => {
//       dir.items.forEach((fileRef) => {
//         deleteFile(ref.fullPath, fileRef.name)
//       })
//       dir.prefixes.forEach((folderRef) => {
//         deleteFolderContents(folderRef.fullPath)
//       })
//     })
//     .catch((error) => {
//       console.log(error)
//     })
// }

// function deleteFile(pathToFile, fileName) {
//   const ref = firebase.storage().ref(pathToFile)
//   const childRef = ref.child(fileName)
//   childRef.delete()
// }

export default deleteDoctor
