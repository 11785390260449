const tableStyles = {
  borderColor: '#EDEDED',
  '& .MuiDataGrid-row:hover': {
    backgroundColor: '#EDEDED',
  },
  '& .MuiDataGrid-cell': {
    borderColor: '#EDEDED',
  },
  '& .MuiDataGrid-withBorderColor': {
    borderColor: '#EDEDED',
  },
  '& .Mui-selected': {
    backgroundColor: '#EDEDED !important',
  },
}
export default tableStyles
