import { storageRef } from '../../config/firebase'
const uploadFiles = ({
  files,
  storagePath,
  exactFileName,
  useFilesWithIds = false,
}) =>
  Promise.all(
    files.map((f) =>
      uploadFile({
        fileObject: useFilesWithIds ? f.file : f,
        storagePath,
        exactFileName: useFilesWithIds ? f.id : exactFileName,
      })
    )
  )

export const uploadFile = ({
  fileObject,
  storagePath,
  exactFileName = null,
}) => {
  const { fileName, fileExt, file } = fileObject

  const uploadTask = storageRef
    .child(
      `${storagePath}/${exactFileName ? exactFileName : fileName}.${fileExt}`
    )
    .put(file)

  uploadTask.on(
    'state_changed',
    (snap) => {
      let progress = (snap.bytesTransferred / snap.totalBytes) * 100
      fileObject.uploadProgress = progress
    },
    (error) => {
      console.log('File upload error ->', error)
    },
    () => {
      // uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => (publicUrl = downloadURL))
    }
  )

  return uploadTask.then(() =>
    uploadTask.snapshot.ref
      .getDownloadURL()
      .then((downloadURL) => ({ fileName, publicUrl: downloadURL }))
  )
}

export default uploadFiles
