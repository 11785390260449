import React, { useState } from 'react'
import './ClientsList.sass'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { useEffect } from 'react'
import getCollection from '../../../utils/db/getCollection'
import DataTable from '../../../ui/DataTable/DataTable'
import { isEmpty } from 'lodash'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import { withRouter } from 'react-router-dom'
import { db } from '../../../config/firebase'

function ClientsList({ ...router }) {
  const [clients, setClients] = useState(null)
  const [specialUsers, setSpecialUsers] = useState([])

  useEffect(() => {
    let usersListener = () => null

    getCollection({
      path: 'users',
      docIdName: 'id',
      orderBy: { fieldPath: 'created', orderDir: 'desc' },
    }).then((result) => {
      setClients(result)

      usersListener = db
        .collection('schedule')
        .onSnapshot((data) =>
          setSpecialUsers(
            data.docs
              .filter((d) => d.data().status === 'awaitResult' && true)
              .map((u) => u.data().userId)
          )
        )
    })

    return () => {
      usersListener()
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(clients)) {
      setClients(
        clients.map((c) => {
          if (specialUsers.includes(c.id)) {
            c.eventType = 'awaitResult'
          } else {
            c.eventType = null
          }
          return c
        })
      )
    }
  }, [specialUsers])

  const columns = [
    {
      field: 'created',
      headerClassName: 'TableHeadCol',
      headerName: 'Дата регистрации',
      // cellClassName: 'VolumeTable-LastUpdateCell Caption-Regular',
      width: 200,
      // valueGetter: (params) => {
      //   if (isEmpty(params.row.averageDayVol)) return null
      //   return params.row.averageDayVol.updated.seconds
      // },
      renderCell: (params) =>
        !isEmpty(params.row.created)
          ? formatDateAsDayMontYearHHMM(params.row.created)
          : null,
    },
    {
      field: 'lastName',
      headerName: 'Фио',
      headerClassName: 'TableHeadCol',
      minWidth: 250,
      // flex: 1,
      valueGetter: (params) => `${params.row.lastName} ${params.row.name}`,
      renderCell: (params) => `${params.row.lastName} ${params.row.name}`,
    },
    {
      field: 'email',
      headerClassName: 'TableHeadCol',
      headerName: 'E-mail',
      width: 300,
    },
    {
      field: 'phone',
      headerClassName: 'TableHeadCol',
      headerName: 'Телефон',
      width: 130,
      renderCell: (params) => `+${params.row.phone}`,
    },
    {
      field: 'city',
      headerClassName: 'TableHeadCol',
      headerName: 'Город',
      width: 130,
    },
    {
      field: 'role',
      headerClassName: 'TableHeadCol',
      headerName: 'Роль',
      width: 130,
      renderCell: (params) =>
        params.row.role === 'doctor' ? 'Врач' : 'Клиент',
    },
    {
      field: 'lastEvent',
      headerClassName: 'TableHeadCol',
      headerName: 'Посл. событие',
      width: 300,
      valueGetter: (params) => params.row.eventType,
      renderCell: (params) =>
        params.row.eventType === 'awaitResult' ? (
          <div className="EventTag">Ожидание результата</div>
        ) : (
          '-'
        ),
    },
    // {
    //   field: 'categoryList',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'КС',
    //   width: 130,
    // },
    // {
    //   field: 'dayVolume',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'Сред. объем за день (лотов)',
    //   width: 200,
    //   valueGetter: (params) => {
    //     if (isEmpty(params.row.averageDayVol)) return '0'

    //     return params.row.averageDayVol.volume
    //       ? params.row.averageDayVol.volume.toLocaleString()
    //       : '0'
    //   },
    //   type: 'number',
    //   sortComparator: (v1, v2) =>
    //     toNumber(v1.replace(/\s/g, '')) - toNumber(v2.replace(/\s/g, '')),
    // },
    // {
    //   field: 'updated',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'Обн. из биржи',
    //   cellClassName: 'VolumeTable-LastUpdateCell Caption-Regular',
    //   width: 200,
    //   valueGetter: (params) => {
    //     if (isEmpty(params.row.averageDayVol)) return null
    //     return params.row.averageDayVol.updated.seconds
    //   },
    //   renderCell: (params) =>
    //     !isEmpty(params.row.averageDayVol)
    //       ? formatDateAsDayMontYearHHMM(
    //           params.row.averageDayVol.updated.seconds * 1000
    //         )
    //       : null,
    // },
    // {
    //   field: 'userTag',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'Тэг',
    //   width: 200,
    //   type: 'singleSelect',
    //   editable: true,
    //   valueGetter: (params) => {
    //     if (isEmpty(params.row.candleScreenerSettings)) return ''
    //     return params.row.candleScreenerSettings.tag
    //   },
    //   valueOptions: ({ row }) => row.candleScreenerTags,
    // },
    // {
    //   field: 'userPercent',
    //   headerClassName: 'TableHeadCol',
    //   headerName: '% от объема',
    //   width: 130,
    //   editable: true,
    //   type: 'number',
    //   valueGetter: (params) => {
    //     if (isEmpty(params.row.candleScreenerSettings)) return null
    //     return params.row.candleScreenerSettings.percent
    //   },
    //   renderCell: (params) => params.row.candleScreenerSettings.percent,
    //   // renderEditCell: (params) => (
    //   //   <GridEditInputCell
    //   //     {...params}
    //   //     inputProps={{
    //   //       max: 100,
    //   //       min: 1,
    //   //     }}
    //   //   />
    //   // ),
    // },
    // {
    //   field: 'userMoneyVol',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'Объем в деньгах',
    //   width: 130,
    //   editable: true,
    //   valueGetter: (params) => {
    //     if (isEmpty(params.row.candleScreenerSettings)) return null
    //     return params.row.candleScreenerSettings.moneyVol
    //   },
    //   renderCell: (params) => params.row.candleScreenerSettings.moneyVol,
    // },
    // {
    //   field: 'userTF',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'ТФ наблюдения',
    //   width: 130,
    //   editable: true,
    //   valueGetter: (params) => {
    //     if (isEmpty(params.row.candleScreenerSettings)) return null
    //     return params.row.candleScreenerSettings.tf
    //   },
    //   renderCell: (params) => params.row.candleScreenerSettings.tf,
    // },
    // {
    //   field: 'userSearchStatus',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'Статус поиска',
    //   width: 150,
    //   editable: true,
    //   type: 'boolean',
    //   valueGetter: (params) => {
    //     if (isEmpty(params.row.candleScreenerSettings)) return false
    //     return params.row.candleScreenerSettings.searchStatus
    //   },
    //   renderCell: (params) => (
    //     <StatusTile
    //       className={
    //         params.row.candleScreenerSettings.searchStatus
    //           ? 'StatusTile_theme_active'
    //           : 'StatusTile_theme_notActive'
    //       }
    //       title={
    //         params.row.candleScreenerSettings.searchStatus
    //           ? 'Активный'
    //           : 'Не активный'
    //       }
    //     />
    //   ),
    // },
    // {
    //   field: 'userSettingsUpdated',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'Обновлено',
    //   cellClassName: 'VolumeTable-LastUpdateCell Caption-Regular',
    //   width: 200,
    //   valueGetter: (params) => {
    //     if (isEmpty(params.row.candleScreenerSettings)) return false
    //     return params.row.candleScreenerSettings.updated.seconds * 1000
    //   },
    //   renderCell: (params) => {
    //     if (isEmpty(params.row.candleScreenerSettings)) return null
    //     return formatDateAsDayMontYearHHMM(
    //       params.row.candleScreenerSettings.updated.seconds * 1000
    //     )
    //   },
    // },
    // {
    //   field: 'actions',
    //   headerClassName: 'TableHeadCol',
    //   headerName: 'Действия',
    //   width: 100,
    //   renderCell: (params) => (
    //     <TableActions
    //       {...{
    //         params,
    //         rowOnSave,
    //         rowModesModel,
    //         setRowModesModel,
    //       }}
    //     />
    //   ),
    // },
  ]

  return (
    <RouteComponent
      title="Список клиентов"
      controlls={
        <>
          <Button
            title="Добавить клиента"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/clients/add"
            size={28}
          />
        </>
      }
    >
      <div className="ClientsList">
        <DataTable
          // initialState={{
          //   sorting: {
          //     sortModel: [{ field: 'symbol', sort: 'asc' }],
          //   },
          // }}
          table={{
            // checkboxSelection: true,
            // editMode: 'row',
            // rowModesModel,
            // onRowModesModelChange: (model) => setRowModesModel(model),
            rows: !isEmpty(clients) ? clients : [],
            columns: columns,
            // processRowUpdate: onRowUpdateSubmit,
            onRowDoubleClick: (params) =>
              router.history.push(`/clients/edit/${params.row.id}`),
            // onProcessRowUpdateError={(e) => {
            //   console.log('🚀 ~ file: ToolsTable.jsx:172 ~ ToolsTable ~ e:', e)
            // }}
            // slotProps={{
            //   toolbar: { setTickers, setRowModesModel },
            // }}
            // onRowSelectionModelChange: (ids) => setSelectedRows(ids),
            autoHeight: true,
          }}
        />
      </div>
    </RouteComponent>
  )
}

export default withRouter(ClientsList)
