import React, { useState } from 'react'
import './TileManager.sass'
import PopUp from '../../../../ui/PopUp/PopUp'
import { createForm } from '../../../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../../../ui/StatusMessage/StatusMessage'
import getFieldRenderObject from '../../../../utils/newforms/render/getFieldRenderObject'
import InfoLine from '../../../../ui/InfoLine/InfoLine'
import FormRender from '../../../../utils/newforms/render/FormRender'
import Button from '../../../../ui/Button/Button'
import isFormValid from '../../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../../utils/newforms/getFormValues'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { find, isEmpty, isEqual, isNumber, omit, toNumber } from 'lodash'
import { updateDoc } from '../../../../utils/db/updateDoc'
import Icon from '../../../../ui/Icon/Icon'
import SearchUser from './SearchUser/SearchUser'

function TileManager({ doctors, tileData, onClose }) {
  const [form, setForm] = useState(
    createForm({
      formPattern:
        tileData.eventType === 'group'
          ? new GroupTileForm()
          : new TileForm(tileData),
      ...(tileData.eventType === 'group' ? { formData: tileData } : {}),
    })
  )

  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const doctor = find(doctors, ['doctorId', tileData.doctorId])

  const [submitType, setSubmitType] = useState(
    isEmpty(tileData.user) && tileData.eventType !== 'group' ? 'base' : 'noBase'
  )
  const [pinnedUser, setPinnedUser] = useState(null)

  const onSubmit = () => {
    if (submitType === 'base') {
      if (isEmpty(pinnedUser) && isEmpty(tileData.user.userId)) {
        return setStatusMessage({
          show: true,
          type: 'fail',
          message: 'Пожалуйста, выберите пользователя',
          closeAfter: 5000,
        })
      }
    } else {
      if (!isFormValid({ form })) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        return setStatusMessage({
          show: true,
          type: 'fail',
          message: 'Пожалуйста, заполните все поля формы',
          closeAfter: 5000,
        })
      }
    }

    setIsLoading(true)

    const data = getFormValues({ form })

    if (
      tileData.eventType === 'group' &&
      data.members.length > toNumber(tileData.eventLimit)
    ) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setIsLoading(false)
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Невозможно сохранить группу больше заданного числа',
        closeAfter: 5000,
      })
    }

    const values =
      tileData.eventType === 'group'
        ? data
        : {
            ...omit(data, ['userName', 'phone']),
            user: {
              userId: data.userId ? data.userId : null,
              userName: data.userName,
              phone: data.phone,
            },
          }

    if (submitType === 'base') {
      values.status = 'booked'
      values.userId = pinnedUser.userId
      values.user = {
        userId: pinnedUser.userId,
        userName: `${pinnedUser.lastName} ${pinnedUser.name}`,
        phone: {
          metadata: {
            // country: 'RU',
            // formatInternational: pinnedUser.phone,
            // number: pinnedUser.phone,
            formatNational: `${pinnedUser.phone}`,
          },
        },
      }
    }

    updateDoc({ path: 'schedule', docId: tileData.tileId, data: values })
      .then(() => {
        setIsLoading(false)
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Данные сохранены',
          closeAfter: 5000,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((error) => {
        console.log(
          '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <PopUp
      show
      title={'Запись на прием'}
      className="TileManager"
      close={onClose}
    >
      {tileData.tileId}
      <div className="TileManager-Content">
        <div className="StatickData">
          <InfoLine
            title="Дата"
            content={format(tileData.date.seconds * 1000, 'cccccc, dd.MM.yy', {
              locale: ru,
            })}
          />
          <InfoLine
            title="Время приема: "
            content={`с ${tileData.timeStart} до ${tileData.timeEnd}`}
          />
          <InfoLine title="Врач" content={doctor.title} />
          <InfoLine
            title="Cпециализация"
            content={tileData.specializationTitle}
          />
        </div>
        {tileData.eventType !== 'group' && isEmpty(tileData.user) && (
          <div className="TypeButtons">
            <TypeButton
              isActive={submitType === 'base'}
              icon="search"
              title="Поиск существующего пользователя"
              onClick={() => setSubmitType('base')}
            />
            <TypeButton
              isActive={submitType === 'noBase'}
              icon="user-slash"
              title="Запись без регистрации"
              onClick={() => setSubmitType('noBase')}
            />
          </div>
        )}

        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}

        {submitType === 'base' ? (
          <SearchUser pinnedUser={pinnedUser} setPinnedUser={setPinnedUser} />
        ) : (
          <>
            <div className="Fields">
              <FormRender
                sections={[
                  {
                    fields:
                      tileData.eventType === 'group'
                        ? ['members']
                        : ['status', 'userName', 'phone', 'comment'],
                  },
                ]}
                form={form}
                setForm={setForm}
                errors={showErrors}
              />
            </div>
          </>
        )}

        <div className="Buttons">
          <Button
            title="Сохранить"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрыть"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            onClick={onClose}
          />
        </div>
      </div>
    </PopUp>
  )
}

function TypeButton({ isActive, icon, title, onClick }) {
  return (
    <div
      onClick={onClick}
      className={`TypeButton ${isActive && 'TypeButton_status_active'}`}
    >
      <Icon name={icon} />
      <p className="Title">{title}</p>
    </div>
  )
}

class TileForm {
  constructor(tileData) {
    console.log('🚀 ~ TileForm ~ constructor ~ tileData:', tileData)
    this.userId = {
      field: {
        fieldId: 'userId',
        fieldType: 'input',
        inputType: 'text',
        value: !isEmpty(tileData.user) ? tileData.user.userId : null,
      },
      render: getFieldRenderObject(),
    }
    this.userName = {
      field: {
        fieldId: 'userName',
        fieldType: 'input',
        inputType: 'text',
        label: 'Фио пациента',
        value: !isEmpty(tileData.user) ? tileData.user.userName : '',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        label: 'Контактный телефон',
        value: !isEmpty(tileData.user)
          ? tileData.user.phone.metadata.formatNational
          : '',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.comment = {
      field: {
        fieldId: 'comment',
        fieldType: 'textarea',
        label: 'Комментарий',
        value: tileData.comment,
      },
      render: getFieldRenderObject(),
    }
    this.status = {
      field: {
        fieldId: 'status',
        fieldType: 'select',
        getOptions: [
          { label: 'Предварительная запись', value: 'booked' },
          { label: 'Подтвержденная запись', value: 'confirmed' },
        ],
        value: tileData.status === 'empty' ? 'confirmed' : tileData.status,
        label: 'Статус записи',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.payment = {
      field: {
        fieldId: 'payment',
        value: {
          paymentType: 'cash',
        },
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

class GroupTileForm {
  constructor() {
    this.members = {
      field: {
        fieldId: 'members',
        fieldType: 'fieldset',
        fields: [
          {
            field: {
              fieldId: 'userName',
              fieldType: 'input',
              inputType: 'text',
              label: 'Фио пациента',
              required: true,
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'phone',
              fieldType: 'phone',
              label: 'Контактный телефон',
              required: true,
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'status',
              fieldType: 'select',
              getOptions: [
                { label: 'Предварительная запись', value: 'booked' },
                { label: 'Подтвержденная запись', value: 'confirmed' },
              ],
              label: 'Статус записи',
              required: true,
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'comment',
              fieldType: 'textarea',
              label: 'Комментарий',
            },
            render: getFieldRenderObject(),
          },
          {
            field: {
              fieldId: 'userId',
              fieldType: 'input',
              inputType: 'text',
              value: null,
            },
            render: getFieldRenderObject(),
          },
        ],
        required: false,
        systemField: true,
      },
      render: getFieldRenderObject(),
    }

    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.payment = {
      field: {
        fieldId: 'payment',
        value: {
          paymentType: 'cash',
        },
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
  }
}

export default TileManager
