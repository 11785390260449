import { db } from '../../config/firebase'

export const setDoc = ({ path, docId, data }) =>
  db
    .collection(path)
    .doc(docId)
    .set(data)
    .then(() => docId)
    .catch((e) => {
      console.log('🚀 ~ file: setDoc.js ~ line 25 ~ setDoc ~ e', e)
      return 'fail'
    })
