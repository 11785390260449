import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyBSYzFkbn72Z40Kp0qUnuGiK30uKGsN7Cc',
  authDomain: 'isee-asd.firebaseapp.com',
  projectId: 'isee-asd',
  storageBucket: 'isee-asd.appspot.com',
  messagingSenderId: '123290679863',
  appId: '1:123290679863:web:183640b14618b58f741db2',
}

firebase.initializeApp(firebaseConfig)

const firebaseSideEffect = firebase.initializeApp(
  firebaseConfig,
  'Side Effect instance'
)

const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const { storage } = firebase
const functions = firebase.app().functions('europe-west3')

export { db, storage, storageRef, firebase, firebaseSideEffect, functions }
