import React, { useState, useEffect } from 'react'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import getDoc from '../../../utils/db/getDoc'
import { db } from '../../../config/firebase'
import { updateDoc } from '../../../utils/db/updateDoc'

const DeleteCategoryManager = ({ categoryId, close }) => {
  const [catData, setCatData] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const onDelete = () => {
    setIsLoading(true)
    deleteCat({ categoryId }).then(() => {
      window.location.reload()
    })
  }

  useEffect(() => {
    getDoc({
      path: `settings/price/categories`,
      docId: categoryId,
    }).then((cat) => setCatData(cat))
  }, [])

  return (
    <DeletePopUp
      title="Удаление категории"
      warningMessage={
        <>
          Вы уверены, что хотите удалить категорию{' '}
          <span className="Bolder">"{catData.title}"</span>?
        </>
      }
      closePopUp={close}
      submitDelete={onDelete}
      isLoading={isLoading}
    />
  )
}

const deleteCat = ({ categoryId }) =>
  updateDoc({
    path: 'settings/price/categories',
    docId: categoryId,
    data: { isDeleted: true },
  })

export default DeleteCategoryManager
