import { DataGrid, GridColumnMenu, GridRowModes } from '@mui/x-data-grid'
import React from 'react'
import Icon from '../Icon/Icon'
import Spinner from '../Spinner/Spinner'
import tableStyles from '../../styles/table'
import { useState } from 'react'

function DataTable({ initialState, table }) {
  const [pageSize, setPageSize] = useState(25)

  return (
    <div className="DataTable">
      <DataGrid
        initialState={{
          ...initialState,
          pagination: { paginationModel: { pageSize } },
        }}
        {...table}
        onRowEditStart={(params, event) => (event.defaultMuiPrevented = true)}
        onRowEditStop={(params, event) => (event.defaultMuiPrevented = true)}
        // rows={!isEmpty(tickers) ? tickers : []}
        // columns={columns}
        rowsPerPageOptions={[25, 50, 100]}
        pageSize={pageSize}
        // processRowUpdate={onRowUpdateSubmit}
        // onProcessRowUpdateError={(e) => {
        //   console.log('🚀 ~ file: ToolsTable.jsx:172 ~ ToolsTable ~ e:', e)
        // }}
        // slotProps={{
        //   toolbar: { setTickers, setRowModesModel },
        // }}
        // autoHeight
        sx={tableStyles}
        slots={{ columnMenu: CustomColumnMenu }}
        getRowId={(row) => row.id}
      />
    </div>
  )
}

export const TableActions = ({
  params,
  rowOnSave,
  rowModesModel,
  setRowModesModel,
}) => {
  const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit

  return (
    <div className="TableActions">
      {isInEditMode ? (
        <>
          <div className="TableActions-IconContainer">
            <Icon
              name="save"
              onClick={() =>
                setRowModesModel({
                  ...rowModesModel,
                  [params.id]: {
                    mode: GridRowModes.View,
                  },
                })
              }
            />
          </div>
          <div className="TableActions-IconContainer">
            <Icon
              name="times"
              onClick={() =>
                setRowModesModel({
                  ...rowModesModel,
                  [params.id]: {
                    mode: GridRowModes.View,
                    ignoreModifications: true,
                  },
                })
              }
            />
          </div>
        </>
      ) : rowOnSave.id === params.id && rowOnSave.status === 'saving' ? (
        <div className="TableActions-IconContainer">
          <Spinner type="button" theme="gray" />
        </div>
      ) : rowOnSave.id === params.id && rowOnSave.status === 'success' ? (
        <div className="TableActions-IconContainer TableActions-IconContainer_theme_success">
          <Icon name="check-circle" weight="solid" />
        </div>
      ) : (
        <div className="TableActions-IconContainer">
          <Icon
            name="pencil-alt"
            onClick={() =>
              setRowModesModel({
                ...rowModesModel,
                [params.id]: { mode: GridRowModes.Edit },
              })
            }
          />
        </div>
      )}
    </div>
  )
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      components={{
        ColumnMenuColumnsItem: null,
      }}
    />
  )
}

export default DataTable
