import React from 'react'
import Button from '../Button'

function SaveButton({ isLoading, onClick }) {
  return (
    <Button
      isLoading={isLoading}
      onClick={onClick}
      title="Сохранить"
      theme="solid"
      icon="check"
      fill="accent"
    />
  )
}

export default SaveButton
