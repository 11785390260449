import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { useState } from 'react'
import Button from '../Button/Button'
import { useEffect } from 'react'

function CityChooser({ activeCityId, setActiveCityId }) {
  const { cities } = useContext(AuthContext)

  return (
    <div className="CitiesControll">
      {cities.map((c) => (
        <Button
          title={c.title}
          fill={c.id === activeCityId ? 'accent' : 'normal'}
          theme="solid"
          onClick={() => setActiveCityId(c.id)}
          size={28}
        />
      ))}
    </div>
  )
}

export default CityChooser
