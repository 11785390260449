import React, { useState } from 'react'
import './Docs.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import Button from '../../ui/Button/Button'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../RouteComponent/RouteComponent'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import { isNull } from 'lodash'
import Spinner from '../../ui/Spinner/Spinner'
import { createForm } from '../../utils/newforms/createForm'
import FieldRender from '../../utils/newforms/render/FieldRender'
import fileToBase64 from '../../utils/files/fileToBase64'
import uploadDocument from './functions/uploadDocument'

function Docs({ ...router }) {
  const [form, setForm] = useState(
    createForm({
      formPattern: new DocsForm(),
    })
  )

  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  const onFileAdded = (newForm, fieldId) => {
    fileToBase64(newForm[fieldId].value.file).then((converted) => {
      newForm[fieldId].value.fileUrl = converted
      setForm(newForm)
    })
  }

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, добавьте файл',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)

    uploadDocument({ form, setForm })
      .then((resp) => {
        setIsLoading(false)
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Данные сохранены',
          closeAfter: 5000,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })

      .catch((error) => {
        console.log(
          '🚀 ~ file: Settings.jsx ~ line 79 ~ onSubmit ~ error',
          error
        )
      })
  }

  return (
    <RouteComponent title={'Документы'}>
      {!isNull(form) ? (
        <div className="Settings-Content">
          {statusMessage.show && (
            <StatusMessage
              className="Site-StatusMessage"
              type={statusMessage.type}
              message={statusMessage.message}
            />
          )}
          <FieldRender
            key="loyalty"
            field={form.loyalty}
            form={form}
            setForm={(newForm) => onFileAdded(newForm, 'loyalty')}
            showErrors={showErrors}
          />
          <FieldRender
            key="policy"
            field={form.policy}
            form={form}
            setForm={(newForm) => onFileAdded(newForm, 'policy')}
            showErrors={showErrors}
          />
          <FieldRender
            key="personal"
            field={form.personal}
            form={form}
            setForm={(newForm) => onFileAdded(newForm, 'personal')}
            showErrors={showErrors}
          />

          <div className="Buttons">
            <Button
              title="Сохранить"
              theme="solid"
              fill="accent"
              iconPosition="right"
              size={48}
              isLoading={isLoading}
              onClick={onSubmit}
            />
          </div>
        </div>
      ) : (
        <Spinner type="module" />
      )}
    </RouteComponent>
  )
}

class DocsForm {
  constructor() {
    this.loyalty = {
      field: {
        fieldId: 'loyalty',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Файл 1',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
    this.policy = {
      field: {
        fieldId: 'policy',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Файл 2',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
    this.personal = {
      field: {
        fieldId: 'personal',
        fieldType: 'inputFile',
        inputType: 'file',
        label: 'Файл 3',
        icon: 'ellipsis-v',
      },
      render: getFieldRenderObject(),
    }
  }
}

export default withRouter(Docs)
