import React, { useState } from 'react'
import './EventTile.sass'
import Icon from '../../../../ui/Icon/Icon'
import Button from '../../../../ui/Button/Button'
import useToggleOptions from '../../../../hooks/useToggleOptions'
import { isEmpty, toNumber } from 'lodash'
import DeleteEventPopUp from './DeleteEventPopUp'

function EventTile({
  tileId,
  timeStart,
  timeEnd,
  specializationTitle,
  user,
  payment,
  comment,
  status,
  setTileOnEdit,
  eventType,
  members,
  eventLimit,
  showEdit,
}) {
  const [showOption, toggleOption] = useToggleOptions()
  const [showDeletePopUp, setShowDeletePopUp] = useState(null)

  return (
    <div
      className={`EventTile ${
        eventType === 'group' ? 'EventTile_theme_group' : ''
      } EventTile_theme_${status}`}
    >
      {showDeletePopUp && (
        <DeleteEventPopUp
          tileId={tileId}
          eventType={showDeletePopUp}
          closePopUp={() => setShowDeletePopUp(null)}
        />
      )}
      {eventType === 'group' && (
        <div className="GroupLabel">
          <Icon name="users" weight="solid" />
        </div>
      )}
      <p className="Date">
        с {timeStart} до {timeEnd}
      </p>
      <p className="Title">{specializationTitle}</p>
      {!isEmpty(user) && (
        <>
          <p className="Client">{user.userName}</p>
          <p className="PhoneNumber">{user.phone.metadata.formatNational}</p>
        </>
      )}
      {eventType === 'group' && (
        <p className="Description">
          <Icon
            name={
              members.length === toNumber(eventLimit) ? 'lock' : 'lock-open'
            }
          />
          Группа: {members.length} / {eventLimit}
        </p>
      )}
      <div className="TileDetails">
        {!isEmpty(payment) ? (
          <div className="Payment">
            <span className="PaymentTitle">Оплата:</span>{' '}
            <div className="Tag Cash">
              <Icon name="wallet" />
            </div>
          </div>
        ) : (
          <span></span>
        )}

        <div className="Options">
          <div className="Tag OptionsTag" onClick={() => toggleOption(tileId)}>
            <Icon name="ellipsis-v" />
          </div>
          {showOption === tileId && (
            <div className="OptionsBox">
              {isEmpty(user) ? (
                <>
                  <Button
                    icon="user"
                    theme="transparent-hug"
                    type="button"
                    title="Записать"
                    onClick={() => setTileOnEdit(tileId)}
                  />
                  {showEdit && (
                    <Button
                      icon="trash"
                      theme="transparent-hug"
                      type="button"
                      title="Удалить"
                      onClick={() => setShowDeletePopUp('deleteTile')}
                    />
                  )}
                </>
              ) : (
                <>
                  <Button
                    icon="user"
                    theme="transparent-hug"
                    type="button"
                    title="Редакт."
                    onClick={() => setTileOnEdit(tileId)}
                  />
                  {showEdit && (
                    <Button
                      icon="user-slash"
                      theme="transparent-hug"
                      type="button"
                      title="Отменить"
                      onClick={() => setShowDeletePopUp('deleteUser')}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default EventTile
