import React from 'react'
import { Route } from 'react-router-dom'
import ClientsList from './ClientsList/ClientsList'
import ClientsManager from './ClientsManager/ClientsManager'

function Clients() {
  return (
    <>
      <Route path="/clients" exact component={ClientsList} />
      <Route path="/clients/add" exact component={ClientsManager} />
      <Route path="/clients/edit/:id" exact component={ClientsManager} />
    </>
  )
}

export default Clients
