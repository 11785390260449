import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import getCollection from '../../../../utils/db/getCollection'
import { cloneDeep, find, isEmpty, orderBy } from 'lodash'
import { Img } from 'react-image'
import Icon from '../../../../ui/Icon/Icon'
import './DoctorsPanel.sass'

function DoctorsPanel({ doctors, setDoctors, schedule }) {
  const setActiveDoctor = (doctorId) => {
    const clone = cloneDeep(doctors)
    clone.forEach((d) => {
      d.isActive = d.doctorId == doctorId
      return d
    })
    setDoctors(clone)
  }

  return (
    <div className="DoctorsPanel">
      {!isEmpty(doctors) &&
        doctors.map((d) => (
          <DoctorTile
            key={d.doctorId}
            {...d}
            onClick={() => setActiveDoctor(d.doctorId)}
            bookedCounter={
              schedule.filter(
                (s) => s.doctorId === d.doctorId && s.status === 'booked'
              ).length
            }
          />
        ))}
    </div>
  )
}

function DoctorTile({ isActive, title, photos, onClick, bookedCounter }) {
  return (
    <div
      className={`Checkout-DoctorTile ${
        isActive && 'Checkout-DoctorTile_theme_active'
      }`}
      onClick={onClick}
    >
      <div className="Avatar">
        {!isEmpty(photos) ? (
          <Img src={find(photos, ['isAvatar', true]).publicUrl} />
        ) : (
          <div className="DoctorAvatar">
            <Icon name="user-md" />
          </div>
        )}
        {bookedCounter ? (
          <span className="ScheduleTag">{bookedCounter}</span>
        ) : null}
      </div>
      <div className="Title">{title}</div>
    </div>
  )
}
export default DoctorsPanel
