import { useState } from 'react'

const useToggleOptions = () => {
  const [showOptions, setShowOptions] = useState(null)

  const toggleOption = (id) => {
    if ((!id && id !== 0) || id === showOptions) setShowOptions(null)
    else setShowOptions(id)
  }

  return [showOptions, toggleOption]
}

export default useToggleOptions
