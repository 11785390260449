import React, { useEffect, useState } from 'react'
import './DoctorsManager.sass'
import { withRouter } from 'react-router'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import Button from '../../../ui/Button/Button'
import { createForm } from '../../../utils/newforms/createForm'
import FormRender from '../../../utils/newforms/render/FormRender'
import getFieldRenderObject from '../../../utils/newforms/render/getFieldRenderObject'
import RouteComponent from '../../RouteComponent/RouteComponent'
import PhotoManager from '../../PhotoManager/PhotoManager'
import syncForm from '../../../utils/newforms/changed/syncForm'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { omit } from 'lodash'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import getDoc from '../../../utils/db/getDoc'
import uploadPhotos from './functions/uploadPhotos'
import SpecializationModule from './SpecializationModule/SpecializationModule'

function DoctorsManager({ ...router }) {
  const doctorId = router.match.params.id
  const [form, setForm] = useState(
    !doctorId ? createForm({ formPattern: new DoctorForm() }) : null
  )
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)
  const [doctorCats, setDoctorCats] = useState([])
  const [doctorServices, setDoctorServices] = useState([])

  useEffect(() => {
    if (doctorId) {
      getDoc({ path: 'doctors', docId: doctorId }).then((data) => {
        setForm(
          createForm({
            formPattern: new DoctorForm(),
            formData: data,
          })
        )
        setDoctorCats(data.doctorCats)
        setDoctorServices(data.doctorServices)
      })
    }
  }, [doctorId])

  // useEffect(() => {
  //   if (
  //     form &&
  //     form.lang.value &&
  //     prevLang &&
  //     prevLang.lang.value &&
  //     form.lang.value !== prevLang.lang.value
  //   ) {
  //     if (tourId) {
  //       getDoc({ path: 'tours', docId: tourId }).then((data) =>
  //         setForm(
  //           createForm({
  //             formPattern: new TourForm(),
  //             formData: {
  //               ...data,
  //               ...data[form.lang.value],
  //               lang: form.lang.value,
  //             },
  //           })
  //         )
  //       )
  //     } else {
  //       setForm(
  //         createForm({
  //           formPattern: new TourForm(),
  //         })
  //       )
  //     }
  //   }
  // }, [form])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }

    // if (isEmpty(form.photos.values)) {
    //   window.scrollTo({ top: 0, behavior: 'smooth' })
    //   return setStatusMessage({
    //     show: true,
    //     type: 'fail',
    //     message: 'Пожалуйста, добавъте фото врача',
    //     closeAfter: 5000,
    //   })
    // }

    setIsLoading(true)

    const data = getFormValues({ form })

    const values = {
      ...data,
      doctorCats,
      doctorServices,
      updated: new Date(),
      photos: data.photos
        .filter((p) => !p.needUpload)
        .map((p) => omit(p, ['file', 'fileUrl'])),
    }

    const opRef = doctorId
      ? updateDoc({ path: 'doctors', docId: doctorId, data: values })
      : addDoc({ path: 'doctors', data: values })

    opRef.then((docId) =>
      uploadPhotos({ photos: data.photos, docId })
        .then(() => {
          router.history.push(`/doctors/edit/${docId}`)
          setIsLoading(false)
          setStatusMessage({
            show: true,
            type: 'success',
            message: 'Данные сохранены',
            closeAfter: 5000,
          })
          window.scrollTo({ top: 0, behavior: 'smooth' })
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: ManageProjectPage.jsx ~ line 79 ~ onSubmit ~ error',
            error
          )
        })
    )
  }

  return (
    <RouteComponent title={doctorId ? 'Редактировать врача' : 'Добавить врача'}>
      <div className="DoctorsManager-Content">
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <p className="Char">
          <FormRender
            sections={[
              {
                fields: ['title', 'description', 'startingYear'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </p>
        <p className="Title">Специализация</p>
        <div className="MainInfo">
          <SpecializationModule
            doctorCats={doctorCats}
            setDoctorCats={setDoctorCats}
            doctorServices={doctorServices}
            setDoctorServices={setDoctorServices}
          />
        </div>
        <p className="Title">Фото</p>
        <PhotoManager
          projectId={doctorId}
          formData={form ? form.photos.values : null}
          syncState={(moduleState) =>
            syncForm({
              propIdentifier: 'photos',
              form,
              setForm,
              newForm: moduleState,
            })
          }
        />
        {/* <p className="Title">Описание</p>
        <div className="MainInfo">
          <FormRender
            sections={[
              {
                fields: ['description'],
              },
            ]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
        </div> */}

        <div className="Buttons">
          <Button
            title="Сохранить"
            theme="solid"
            fill="accent"
            iconPosition="right"
            size={48}
            isLoading={isLoading}
            onClick={onSubmit}
          />
          <Button
            title="Закрыть"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            isLoading={isLoading}
            onClick={() => router.history.push('/doctors')}
          />
        </div>
      </div>
    </RouteComponent>
  )
}

class DoctorForm {
  constructor() {
    this.title = {
      field: {
        fieldId: 'title',
        fieldType: 'input',
        inputType: 'text',
        label: 'Фио врача',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.startingYear = {
      field: {
        fieldId: 'startingYear',
        fieldType: 'input',
        inputType: 'text',
        label: 'Год начала работы',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.description = {
      field: {
        fieldId: 'description',
        fieldType: 'input',
        inputType: 'text',
        label: 'Профессия',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.photos = {
      field: { fieldId: 'photos', value: [] },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.isDeleted = {
      field: { fieldId: 'isDeleted', value: false },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: { fieldId: 'created', value: new Date() },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.pos = {
      field: {
        fieldId: 'pos',
        fieldType: 'input',
        inputType: 'number',
        label: 'Позиция в cписке',
      },
      render: getFieldRenderObject(),
    }
  }
}

export default withRouter(DoctorsManager)
