import React, { useContext } from 'react'
import { Route } from 'react-router'
import Doctors from '../../components/Doctors/index'
import Price from '../../components/Price/Price'
import Clients from '../../components/Clients/index'
import Checkout from '../../components/Checkout/Checkout'
import News from '../../components/News/News'
import { AuthContext } from '../../App'
import Docs from '../../components/Docs/Docs'
// import Faq from '../../components/Faq/index'
// import Feeds from '../../components/Feeds/index'
// import Partners from '../../components/Partners/index'
// import Settings from '../../components/Settings/Settings'
// import Qrcode from '../../components/Qrcode/Qrcode'

function Routes() {
  const { user, cities } = useContext(AuthContext)

  return (
    <div className="Routes">
      {user.role === 'doctor' ? (
        <Route path="/" render={() => <Checkout type="doctor" />} />
      ) : (
        <>
          <Route path="/clients" component={Clients} />
          <Route path="/price" component={Price} />
          <Route path="/doctors" component={Doctors} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/news" component={News} />
          <Route path="/pdf" component={Docs} />
        </>
      )}

      {/* <Route path="/faq" component={Faq} />
      <Route path="/feeds" component={Feeds} />
      <Route path="/partners" component={Partners} />
      <Route path="/settings" component={Settings} />
      <Route path="/qrcode" component={Qrcode} /> */}
    </div>
  )
}

export default Routes
