import React, { useState } from 'react'
import './FilterPanel.sass'
import getFieldRenderObject from '../../../../utils/newforms/render/getFieldRenderObject'
import { createForm } from '../../../../utils/newforms/createForm'
import FormRender from '../../../../utils/newforms/render/FormRender'
import CityChooser from '../../../../ui/CityChooser/CityChooser'
import { useContext } from 'react'
import { AuthContext } from '../../../../App'
import { endOfWeek, startOfToday, startOfWeek } from 'date-fns/esm'
import { addDays } from 'date-fns'

function FilterPanel({ form, setForm, activeCityId, setActiveCityId }) {
  return (
    <div className="FilterPanel">
      <div className="CityField">
        <span className="Title">Выбор города:</span>
        <CityChooser
          activeCityId={activeCityId}
          setActiveCityId={setActiveCityId}
        />
      </div>
      <div className="DateField">
        <span className="Title">Неделя: </span>
        <FormRender
          sections={[
            {
              fields: ['date'],
            },
          ]}
          form={form}
          setForm={setForm}
        />
      </div>
    </div>
  )
}

export default FilterPanel
