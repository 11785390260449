import React from 'react'
import './Calendar.sass'
import Button from '../../../../ui/Button/Button'
import { useState } from 'react'
import { useEffect } from 'react'
import getDates from './functions/getDates'
import { format } from 'date-fns'
import ru from 'date-fns/locale/ru'
import EventTile from '../EventTile/EventTile'
import EventManager from '../EventManager/EventManager'
import {
  concat,
  find,
  intersection,
  isEmpty,
  isEqual,
  uniq,
  uniqBy,
} from 'lodash'
import TileManager from '../TileManager/TileManager'
import getCollection from '../../../../utils/db/getCollection'

function Calendar({ schedule, dates, doctors, cityCats, activeCityId, type }) {
  const [settings, setSettings] = useState(null)
  const [tileOnEdit, setTileOnEdit] = useState(null)

  const onAdd = (d) => {
    const activeDoctor = find(doctors, ['isActive', true])

    const intersectedCats = intersection(
      cityCats.map((c) => c.categoryId),
      activeDoctor.doctorCats
    )
    const settings = {
      date: d,
      doctor: activeDoctor,
      categories: intersectedCats.map((c) => {
        const cat = find(cityCats, ['categoryId', c])
        if (cat) {
          return { label: cat.title, value: c }
        }
      }),
      cityId: activeCityId,
    }

    if (!isEmpty(activeDoctor.doctorServices)) {
      getCollection({
        path: 'settings/price/services',
        docIdName: 'serviceId',
      }).then((data) => {
        const catsByServices = []
        activeDoctor.doctorServices.forEach((s) => {
          const serviceInfo = find(data, ['serviceId', s])
          if (serviceInfo) {
            const catData = find(cityCats, [
              'categoryId',
              serviceInfo.categoryId,
            ])
            if (catData) {
              catsByServices.push({
                label: catData.title,
                value: serviceInfo.categoryId,
              })
            }
          }
        })
        settings.categories = concat(
          settings.categories,
          uniqBy(catsByServices, ['value'])
        )
        setSettings(settings)
      })
    } else {
      setSettings(settings)
    }
  }

  return (
    <div
      className="Calendar"
      style={{ gridTemplateColumns: `repeat(${dates.length}, 1fr)` }}
    >
      {tileOnEdit && (
        <TileManager
          doctors={doctors}
          tileData={find(schedule, ['tileId', tileOnEdit])}
          onClose={() => setTileOnEdit(null)}
        />
      )}

      {!isEmpty(settings) && (
        <EventManager {...settings} onClose={() => setSettings(null)} />
      )}
      {dates.map((d) => (
        <CalendarColumn
          date={d}
          onAdd={() => onAdd(d)}
          schedule={schedule}
          cityCats={cityCats}
          setTileOnEdit={setTileOnEdit}
          type={type}
        />
      ))}
    </div>
  )
}

function CalendarColumn({
  date,
  onAdd,
  schedule,
  cityCats,
  setTileOnEdit,
  type,
}) {
  const [tiles, setTiles] = useState(null)
  console.log('🚀 ~ CalendarColumn ~ type:', type)

  useEffect(() => {
    setTiles(
      schedule
        .filter((s) => isEqual(new Date(s.date.seconds * 1000), date))
        .map((t) => {
          t.specializationTitle = find(cityCats, [
            'categoryId',
            t.specialization,
          ])
            ? find(cityCats, ['categoryId', t.specialization]).title
            : ''
          return t
        })
    )
  }, [schedule])

  return (
    <div className="CalendarColumn">
      <div className="DateTitle">
        {format(date, 'cccccc, dd.MM.yy', { locale: ru })}
      </div>
      {type !== 'doctor' && (
        <Button
          icon="plus"
          fill="accent"
          theme="solid"
          type="button"
          size={28}
          onClick={onAdd}
        />
      )}
      {!isEmpty(tiles)
        ? tiles.map((t) => (
            <EventTile
              key={t.tileId}
              {...t}
              setTileOnEdit={setTileOnEdit}
              showEdit={type !== 'doctor'}
            />
          ))
        : 'Нет записей'}
      {type !== 'doctor' && (
        <Button
          icon="plus"
          fill="accent"
          theme="solid"
          type="button"
          size={28}
          onClick={onAdd}
        />
      )}
    </div>
  )
}

export default Calendar
