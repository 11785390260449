import { addDays, isBefore } from 'date-fns'

function getDates(startDate, stopDate) {
  const dateArray = []
  let currentDate = startDate
  while (isBefore(currentDate, addDays(stopDate, 1))) {
    dateArray.push(currentDate)
    currentDate = addDays(currentDate, 1)
  }
  return dateArray
}

export default getDates
