import React, { useEffect, useState } from 'react'
import RouteComponent from '../../RouteComponent/RouteComponent'
import Button from '../../../ui/Button/Button'
import { db } from '../../../config/firebase'
import Spinner from '../../../ui/Spinner/Spinner'
import InfoLine from '../../../ui/InfoLine/InfoLine'
import { formatDateAsDayMontYearHHMM } from '../../../utils/date/dateFormat'
import './DoctorsList.sass'
import { find, isEmpty, orderBy } from 'lodash'
import Img from '../../../ui/Img/Img'
import Icon from '../../../ui/Icon/Icon'
import { withRouter } from 'react-router'
import DeletePopUp from '../../../ui/PopUp/DeletePopUp/DeletePopUp'
import onDoctorDelete from '../DoctorsManager/functions/deleteDoctor'
import SortableList from '../../../ui/SortableList/SortableList'
import arrayMove from 'array-move'
import { updateDoc } from '../../../utils/db/updateDoc'
import getCollection from '../../../utils/db/getCollection'

function DoctorsList({ ...router }) {
  const [doctorsList, setDoctorsList] = useState(null)
  const [deleteDoctor, setDeleteDoctor] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getCollection({
      path: 'settings/price/categories',
      docIdName: 'categoryId',
    }).then((categories) => {
      db.collection('doctors').onSnapshot((docs) => {
        const data = []
        docs.forEach((d) => {
          let specialization = categories.filter((c) =>
            d.data().doctorCats.includes(c.categoryId)
          )

          // if(!isEmpty(d.data().doctorServices)){

          // }

          data.push({ doctorId: d.id, ...d.data(), specialization })
        })
        setDoctorsList(
          orderBy(data, ['pos'], ['asc']).filter((d) => d.isDeleted === false)
        )
      })
    })
  }, [])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newDoctors = arrayMove(doctorsList, oldIndex, newIndex)
    setDoctorsList(newDoctors)
  }

  const updateDoctorsOrder = () => {
    setIsLoading(true)
    Promise.all(
      doctorsList.map((d, i) =>
        updateDoc({
          path: 'doctors',
          docId: d.doctorId,
          data: { pos: i },
        })
      )
    )
      .then(() => setIsLoading(false))
      .catch(() => {
        console.log('error')
      })
  }

  return (
    <RouteComponent
      title="Врачи"
      controlls={
        <>
          <Button
            title="Обновить порядок"
            icon="save"
            fill="accent"
            theme="solid"
            type="button"
            size={28}
            isLoading={isLoading}
            onClick={updateDoctorsOrder}
            className="DoctorsList-UpdateSortButton"
          />
          <Button
            title="Добавить врача"
            icon="plus"
            fill="accent"
            theme="solid"
            type="navlink"
            path="/doctors/add"
            size={28}
          />
        </>
      }
    >
      {deleteDoctor && (
        <DeletePopUp
          title="Удаление врача"
          warningMessage={`Вы уверены, что хотите удалить врача "${deleteDoctor.title}"?`}
          closePopUp={() => setDeleteDoctor(null)}
          submitDelete={() =>
            onDoctorDelete({ doctorId: deleteDoctor.doctorId })
          }
        />
      )}
      <div className="DoctorsCatalog">
        {doctorsList ? (
          isEmpty(doctorsList) ? (
            <p className="EmptyText">Нет врачей</p>
          ) : (
            <SortableList
              className="ToursCatalog-SortableGrid"
              axis="y"
              onSortEnd={onSortEnd}
              pressDelay={200}
              items={doctorsList.map((d, i) => (
                <DoctorTile
                  key={`doctor-${i}`}
                  {...d}
                  onEdit={() =>
                    router.history.push(`/doctors/edit/${d.doctorId}`)
                  }
                  onDelete={() =>
                    setDeleteDoctor({
                      title: d.title,
                      doctorId: d.doctorId,
                    })
                  }
                />
              ))}
            />
          )
        ) : (
          <Spinner type="module" />
        )}
      </div>
    </RouteComponent>
  )
}

function DoctorTile({
  title,
  specialization,
  updated,
  photos,
  onEdit,
  onDelete,
}) {
  return (
    <div className="DoctorTile" onDoubleClick={onEdit}>
      <div className="Avatar">
        {!isEmpty(photos) ? (
          <Img src={find(photos, ['isAvatar', true]).publicUrl} />
        ) : (
          <div className="DoctorAvatar">
            <Icon name="user-md" />
          </div>
        )}
      </div>
      <div className="Content">
        <p className="Title">{title}</p>
        <InfoLine
          title="Специализация"
          content={<span>{specialization.map((s) => s.title).join(', ')}</span>}
        />
      </div>
      <div className="Options">
        <div className="OptionButton Gray" onClick={onEdit}>
          <Icon name="expand-arrows-alt" />
        </div>
        <div className="OptionButton Accent" onClick={onEdit}>
          <Icon name="pencil-alt" />
        </div>
        <div className="OptionButton Red" onClick={onDelete}>
          <Icon name="trash-alt" />
        </div>
      </div>
    </div>
  )
}

export default withRouter(DoctorsList)
