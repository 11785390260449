import React from 'react'
import './ClientHistory.sass'
import Icon from '../../../../ui/Icon/Icon'

function ClientHistory() {
  return (
    <div className="ClientHistory">
      <HistoryEventTile
        date="28.02.2024"
        content={<p className="Title">Пользователь зарегистрирован</p>}
      />
      <HistoryEventTile
        date="05.03.2024"
        content={
          <div className="CheckoutEvent">
            <p className="Title">Запись на прием</p>
            <p>Функциональная диагностика</p>
            <p>Врач: Проценко Александра Павловна</p>
            <p>
              Статус:{' '}
              <span style={{ color: '#FF9600' }}>предварительная запись</span>
            </p>
          </div>
        }
      />

      <HistoryEventTile
        date="05.03.2024"
        content={
          <div className="CheckoutEvent">
            <p className="Title">Запись на прием</p>
            <p>Функциональная диагностика</p>
            <p>Врач: Проценко Александра Павловна</p>
            <p>
              Статус:{' '}
              <span style={{ color: '#09A8CA' }}>подтвержденная запись</span>
            </p>
          </div>
        }
      />
      <HistoryEventTile
        date="05.03.2024"
        content={
          <div className="CheckoutEvent">
            <p className="Title">Запись на прием</p>
            <p>Функциональная диагностика</p>
            <p>Врач: Проценко Александра Павловна</p>
            <p>
              Статус:{' '}
              <span style={{ color: '#09A8CA' }}>
                ожидание результатов сеанса
              </span>
            </p>
            <p className="DownloadFile">
              <span className="Date">04.03.2024 - </span>
              <div className="Icon-Container">
                <Icon name="file-pdf" weight="solid" />
              </div>
              <span>Скачать документ</span>
              <Icon name="times" className={'Cancel'} />
            </p>
          </div>
        }
      />
    </div>
  )
}

function HistoryEventTile({ date, content }) {
  return (
    <div className="HistoryEventTile">
      <div className="Date">{date}</div>
      <div className="Content">{content}</div>
    </div>
  )
}

export default ClientHistory
