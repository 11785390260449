import React from 'react'
import Button from '../Button'

function CancelButton({ onClick }) {
  return (
    <Button
      // size={32}
      onClick={onClick}
      title="Закрыть"
      fill="normal"
      theme="bounded"
    />
  )
}

export default CancelButton
