import React, { useContext, useEffect, useState } from 'react'
import RouteComponent from '../RouteComponent/RouteComponent'
import Button from '../../ui/Button/Button'
import { AuthContext } from '../../App'
import './Price.sass'
import CategoryManager from './CategoryManager/CategoryManager'
import addDoc from '../../utils/db/addDoc'
import getCollection from '../../utils/db/getCollection'
import promiseAllValues from '../../utils/promiseAllValues'
import CategoryPriceTile from './CategoryPriceTile/CategoryPriceTile'
import { isEmpty, last, tail, toInteger, toNumber } from 'lodash'
import { priceData } from './priceData'

// import Button from '../../../../../../ui/Button/Button'
// import CategoryManager from './CategoryManager/CategoryManager'
// import './CategoryPrice.sass'
// import { db } from '../../../../../../config/firebase'
// import rootDbPath from '../../../../../../utilities/db/rootDbPath'
// import { filter, isEmpty } from 'lodash'
import DeleteCategoryManager from './CategoryManager/DeleteCategoryManager'
import ServiceManager from './ServiceManager/ServiceManager'
// import CategoryPriceTile from './CategoryPriceTile/CategoryPriceTile'
// import ServiceManager from './ServiceManager/ServiceManager'
// import DeleteServiceManager from './ServiceManager/DeleteServiceManager'

function CategoryPrice({ accountId, extensionId, officeId }) {
  const [popUp, setPopUp] = useState({ type: null, instanceId: null, pos: 0 })

  const { cities } = useContext(AuthContext)
  const [activeCityId, setActiveCityId] = useState(cities[0].id)

  const [categories, setCategories] = useState(null)
  const [services, setServices] = useState(null)

  const togglePopUp = ({
    type,
    show,
    editMode = false,
    instanceId = null,
    serviceId = null,
  }) => setPopUp({ type, show, editMode, instanceId, serviceId })

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
        orderBy: { fieldPath: 'pos', orderDir: 'asc' },
      }),
      services: getCollection({
        path: 'settings/price/services',
        docIdName: 'serviceId',
        orderBy: { fieldPath: 'pos', orderDir: 'asc' },
      }),
    }).then((result) => {
      setCategories(result.categories.filter((c) => c.isDeleted !== true))
      setServices(result.services.filter((s) => s.isDeleted !== true))
    })
  }, [])

  // useEffect(() => {
  //   db.collection(`${rootDbPath(accountId)}/extensions/${extensionId}/categories`).onSnapshot((snap) => {
  //     const data = []
  //     snap.forEach((s) =>
  //       data.push({
  //         ...s.data(),
  //         categoryId: s.id,
  //       }),
  //     )
  //     setCategories(data)
  //   })
  // }, [])

  // useEffect(() => {
  //   db.collection(`${rootDbPath(accountId)}/extensions/${extensionId}/services`).onSnapshot((snap) => {
  //     const data = []
  //     snap.forEach((s) =>
  //       data.push({
  //         ...s.data(),
  //         serviceId: s.id,
  //       }),
  //     )
  //     setServices(data)
  //   })
  // }, [])

  // const categoriesByOfficeId = !isEmpty(categories) ? filter(categories, ['officeId', officeId]) : null

  return (
    <RouteComponent
      title="Прайс"
      controlls={
        <>
          <Button
            title="Создать категорию"
            icon="plus"
            fill="accent"
            theme="solid"
            onClick={() =>
              setPopUp({ type: 'cat', pos: toNumber(last(categories).pos) + 1 })
            }
            size={28}
          />
        </>
      }
    >
      <div className="CitiesControll">
        {cities.map((c) => (
          <Button
            title={c.title}
            fill={c.id === activeCityId ? 'accent' : 'normal'}
            theme="solid"
            onClick={() => setActiveCityId(c.id)}
            size={28}
          />
        ))}
      </div>
      <div className="PriceContainer">
        {!isEmpty(categories) &&
          !isEmpty(services) &&
          categories
            .filter((c) => c.cityId === activeCityId)
            .map((c) => (
              <CategoryPriceTile
                categoryData={c}
                services={services}
                setServices={setServices}
                setPopUp={setPopUp}
              />
            ))}
      </div>

      {/* <button onClick={() => test()}>click it</button> */}
      {popUp.type === 'cat' && (
        <CategoryManager
          activeCityId={activeCityId}
          categoryId={popUp.instanceId}
          nextPos={popUp.pos}
          onClose={() => setPopUp({ type: null })}
        />
      )}
      {popUp.type === 'delCat' && (
        <DeleteCategoryManager
          categoryId={popUp.instanceId}
          close={() => togglePopUp({ type: null })}
        />
      )}
      {popUp.type === 'service' && (
        <ServiceManager
          categoryId={popUp.instanceId}
          serviceId={popUp.serviceId}
          nextPos={popUp.pos}
          onClose={() => setPopUp({ type: null })}
        />
      )}
    </RouteComponent>
    // <div className="CategoryPrice">
    //   <Button
    //     onClick={() => setPopUp({ type: 'cat' })}
    //     // icon={icon}
    //     theme="bounded"
    //     size={28}
    //     title="Создать категорию"
    //     // color={color}
    //     // isLoading={isLoading}
    //     spinnerColor="gray"
    //     // className={className}
    //   />

    //   {popUp.type === 'delServ' && (
    //     <DeleteServiceManager
    //       show
    //       serviceId={popUp.serviceId}
    //       extensionId={extensionId}
    //       close={() => togglePopUp({ type: null })}
    //     />
    //   )}
    //   {!isEmpty(categoriesByOfficeId) &&
    //     categoriesByOfficeId.map((c) => <CategoryPriceTile services={services} togglePopUp={togglePopUp} {...c} />)}
    // </div>
  )
}

export default CategoryPrice
