import React, { useEffect, useState } from 'react'
import './ClientForm.sass'
import { withRouter } from 'react-router-dom'
import getFieldRenderObject from '../../../../utils/newforms/render/getFieldRenderObject'
import { createForm } from '../../../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../../../ui/StatusMessage/StatusMessage'
import FormRender from '../../../../utils/newforms/render/FormRender'
import Button from '../../../../ui/Button/Button'
import isFormValid from '../../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../../utils/newforms/getFormValues'
import { updateDoc } from '../../../../utils/db/updateDoc'
import addDoc from '../../../../utils/db/addDoc'
import { firebase } from '../../../../config/firebase'
import { setDoc } from '../../../../utils/db/setDoc'
import getDoc from '../../../../utils/db/getDoc'
import checkUserByPhoneAndEmail from './functions/checkUserByPhoneAndEmail'
import getCollection from '../../../../utils/db/getCollection'
import { orderBy, values } from 'lodash'
import PopUp from '../../../../ui/PopUp/PopUp'

function ClientForm({
  insertMode = false,
  onRegComplete = () => null,
  ...router
}) {
  const clientId = router.match ? router.match.params.id : null

  const [form, setForm] = useState(
    !clientId
      ? createForm({
          formPattern: new Form({ insertMode }),
          methods: {
            getDoctors: () =>
              getCollection({
                path: 'doctors',
                docIdName: 'doctorId',
              }).then((res) =>
                orderBy(res, ['title'], ['asc']).map((d) => ({
                  label: d.title,
                  value: d.doctorId,
                }))
              ),
          },
        })
      : null
  )
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (clientId) {
      getDoc({ path: 'users', docId: clientId }).then((data) => {
        setForm(
          createForm({
            formPattern: new Form(),
            formData: data,
            methods: {
              getDoctors: () =>
                getCollection({
                  path: 'doctors',
                  docIdName: 'doctorId',
                }).then((res) =>
                  orderBy(res, ['title'], ['asc']).map((d) => ({
                    label: d.title,
                    value: d.doctorId,
                  }))
                ),
            },
          })
        )
      })
    }
  }, [clientId])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)
    const data = getFormValues({ form })
    const values = {
      ...data,
      updated: new Date(),
    }

    const opRef = clientId
      ? updateDoc({ path: 'users', docId: clientId, data: values })
      : checkUserByPhoneAndEmail({
          email: values.email,
          phone: values.phone,
        }).then(() =>
          firebase
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password)
            .then((result) =>
              Promise.all([
                setDoc({
                  path: 'users',
                  docId: result.user.uid,
                  data: values,
                }),
                // sendSignUpEmail({ to: email, password }),
              ])
            )
        )

    opRef
      .then((docId) => {
        if (insertMode) {
          return onRegComplete({
            userId: docId,
            ...values,
          })
        }

        router.history.push(`/clients/edit/${docId}`)
        setIsLoading(false)
        setStatusMessage({
          show: true,
          type: 'success',
          message: 'Данные сохранены',
          closeAfter: 5000,
        })
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch((error) => {
        setIsLoading(false)
        window.scrollTo({ top: 0, behavior: 'smooth' })
        switch (error) {
          case 'existByPhoneAndEmail':
            setStatusMessage({
              show: true,
              type: 'fail',
              message: 'Указанный номер телефона и E-mail уже используется',
              closeAfter: 5000,
            })
            break
          case 'existByPhone':
            setStatusMessage({
              show: true,
              type: 'fail',
              message: 'Указанный номер телефона уже используется',
              closeAfter: 5000,
            })
            break
          case 'existByEmail':
            setStatusMessage({
              show: true,
              type: 'fail',
              message: 'Указанный E-mail уже используется',
              closeAfter: 5000,
            })
            break
          default:
            break
        }
        console.log('🚀 ~ onSubmit ~ error:', error)
      })
  }

  return (
    <div className="ClientForm">
      {statusMessage.show && (
        <StatusMessage
          className="Site-StatusMessage"
          type={statusMessage.type}
          message={statusMessage.message}
        />
      )}
      <FormRender
        sections={[
          {
            fields: [
              'name',
              'lastName',
              'bDate',
              'gender',
              'phone',
              'phoneVerified',
              'email',
              'password',
              'role',
              'doctorId',
            ],
          },
        ]}
        form={form}
        setForm={setForm}
        errors={showErrors}
        isFormSaved={clientId ? true : false}
        // closeForm={true}
      />
      <div className="Buttons">
        <Button
          title="Сохранить"
          theme="solid"
          fill="accent"
          iconPosition="right"
          size={48}
          isLoading={isLoading}
          onClick={onSubmit}
        />
        {!insertMode && (
          <Button
            title="Закрыть"
            theme="bounded"
            color="gray"
            border="gray"
            size={48}
            isLoading={isLoading}
            onClick={() => router.history.push('/clients')}
          />
        )}
      </div>
    </div>
  )
}

export function ClientRegInsert({ onRegComplete }) {
  return (
    <div className="ClientRegInsert">
      <p className="Title">Регистрация нового клиента</p>
      <ClientForm insertMode onRegComplete={onRegComplete} />
    </div>
  )
}

class Form {
  constructor({ insertMode, disabledFields = [] } = {}) {
    this.avatar = {
      field: {
        fieldId: 'avatar',
        value: Math.floor(Math.random() * 3) + 1,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.name = {
      field: {
        fieldId: 'name',
        fieldType: 'input',
        inputType: 'text',
        label: 'Имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.lastName = {
      field: {
        fieldId: 'lastName',
        fieldType: 'input',
        inputType: 'text',
        label: 'Фамилия',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'input',
        inputType: 'phone',
        mask: 'phone',
        label: 'Телефон',
        required: true,
      },
      render: getFieldRenderObject({
        isChangableAfterSave: false,
      }),
    }
    this.email = {
      field: {
        fieldId: 'email',
        fieldType: 'input',
        inputType: 'email',
        validationPattern: 'email',
        label: 'E-mail',
        required: true,
      },
      render: getFieldRenderObject({
        isChangableAfterSave: false,
      }),
    }
    this.password = {
      field: {
        fieldId: 'password',
        fieldType: 'input',
        inputType: 'password',
        label: 'Пароль',
        required: true,
        value: generatePassword(),
      },
      render: getFieldRenderObject({
        isChangableAfterSave: false,
      }),
    }
    this.phoneVerified = {
      field: {
        fieldId: 'phoneVerified',
        fieldType: 'checkbox',
        label: 'Номер подтвержден',
        value: true,
      },
      render: getFieldRenderObject({
        isChangableAfterSave: false,
      }),
    }
    this.phoneVerificationCode = {
      field: {
        fieldId: 'phoneVerificationCode',
        value: null,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.signUpType = {
      field: {
        fieldId: 'signUpType',
        value: 'manual',
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.created = {
      field: {
        fieldId: 'created',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.updated = {
      field: {
        fieldId: 'updated',
        value: new Date(),
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.version = {
      field: {
        fieldId: 'version',
        value: 1.0,
      },
      render: getFieldRenderObject({ isSystem: true }),
    }
    this.gender = {
      field: {
        fieldId: 'gender',
        fieldType: 'select',
        label: 'Пол',
        getOptions: [
          {
            value: null,
            label: 'Не указано',
          },
          {
            value: 'male',
            label: 'Мужчина',
          },
          {
            value: 'female',
            label: 'Женщина',
          },
        ],
      },
      render: getFieldRenderObject(),
    }
    this.role = {
      field: {
        fieldId: 'role',
        fieldType: 'select',
        label: 'Роль',
        getOptions: [
          {
            value: 'user',
            label: 'Пользователь',
          },
          {
            value: 'doctor',
            label: 'Врач',
          },
        ],
        value: insertMode ? 'user' : '',
      },
      render: getFieldRenderObject(),
    }
    this.doctorId = {
      field: {
        fieldId: 'doctorId',
        fieldType: 'select',
        label: 'Выберите профиль врача',
        getOptions: 'getDoctors',
      },
      render: getFieldRenderObject(),
    }
    this.bDate = {
      field: {
        fieldId: 'bDate',
        fieldType: 'input',
        inputType: 'text',
        mask: 'date',
        label: 'Дата рождения',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.city = {
      field: {
        fieldId: 'city',
        fieldType: 'input',
        inputType: 'text',
        label: 'Город',
      },
      render: getFieldRenderObject(),
    }
  }
}

function generatePassword() {
  var length = 8,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
    retVal = ''
  for (var i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n))
  }
  return retVal
}

export default withRouter(ClientForm)
