import React from 'react'
import RouteComponent from '../RouteComponent/RouteComponent'
import DoctorsPanel from './components/DoctorsPanel/DoctorsPanel'
import './Checkout.sass'
import Calendar from './components/Calendar/Calendar'
import FilterPanel from './components/FilterPanel/FilterPanel'
import { useState } from 'react'
import {
  addDays,
  endOfWeek,
  isAfter,
  isBefore,
  isEqual,
  startOfToday,
  startOfWeek,
} from 'date-fns'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { createForm } from '../../utils/newforms/createForm'
import { useEffect } from 'react'
import getDates from './components/Calendar/functions/getDates'
import {
  cloneDeep,
  concat,
  find,
  intersection,
  isEmpty,
  orderBy,
  uniq,
} from 'lodash'
import getCollection from '../../utils/db/getCollection'
import promiseAllValues from '../../utils/promiseAllValues'
import { db } from '../../config/firebase'

function Checkout({ type }) {
  const [form, setForm] = useState(createForm({ formPattern: new DatesForm() }))
  const { user, cities } = useContext(AuthContext)
  const [activeCityId, setActiveCityId] = useState(cities[0].id)
  const [dates, setDates] = useState(null)
  const [doctors, setDoctors] = useState(null)
  const [cityCats, setCityCats] = useState(null)
  const [schedule, setSchedule] = useState(null)
  const [filteredSchedule, setFilteredSchedule] = useState([])

  useEffect(() => {
    let scheduleListener = () => null
    scheduleListener = db.collection('schedule').onSnapshot((snap) => {
      const tiles = []
      snap.forEach((s) => tiles.push({ tileId: s.id, ...s.data() }))
      setSchedule(orderBy(tiles, ['dateEnd'], ['asc']))
    })
    return () => {
      scheduleListener()
    }
  }, [])

  useEffect(() => {
    if (form.date.value.from && form.date.value.to) {
      setDates(getDates(form.date.value.from, form.date.value.to))
    }
  }, [form])

  useEffect(() => {
    if (!isEmpty(doctors) && form.date.value.from && form.date.value.to) {
      const activeDoctor = find(doctors, ['isActive', true]).doctorId
      const clone = cloneDeep(schedule)
      const filtered = clone.filter((s) => {
        const tileDate = new Date(s.date.seconds * 1000)
        if (
          (isBefore(tileDate, form.date.value.to) ||
            isEqual(tileDate, form.date.value.to)) &&
          (isAfter(tileDate, form.date.value.from) ||
            isEqual(tileDate, form.date.value.from)) &&
          s.cityId === activeCityId &&
          s.doctorId === activeDoctor
        ) {
          return true
        }

        return false
      })
      setFilteredSchedule(filtered)
    }
  }, [schedule, doctors, form, activeCityId])

  useEffect(() => {
    promiseAllValues({
      categories: getCollection({
        path: 'settings/price/categories',
        docIdName: 'categoryId',
        whereQueries: [
          { fieldPath: 'cityId', op: '==', value: activeCityId },
          { fieldPath: 'isDeleted', op: '!=', value: true },
        ],
      }),
      services: getCollection({
        path: 'settings/price/services',
        docIdName: 'serviceId',
      }),
      doctors: getCollection({
        path: 'doctors',
        docIdName: 'doctorId',
        whereQueries: [{ fieldPath: 'isDeleted', op: '!=', value: true }],
      }),
    }).then((result) => {
      const { categories, services } = result
      setCityCats(categories)

      setDoctors(
        orderBy(
          result.doctors.filter((d) => {
            const catsByServices = []
            d.doctorServices.forEach((s) => {
              const serviceInfo = find(services, ['serviceId', s])
              if (serviceInfo) {
                catsByServices.push(serviceInfo.categoryId)
              }
            })

            return !isEmpty(
              intersection(
                categories.map((c) => c.categoryId),
                concat(d.doctorCats, uniq(catsByServices))
              )
            )
          }),
          ['pos'],
          ['asc']
        )
          .filter((d) =>
            type === 'doctor' ? d.doctorId === user.doctorId && true : true
          )
          .map((d, i) => {
            d.isActive = i === 0
            return d
          })
      )
    })
  }, [activeCityId])

  return (
    <RouteComponent title="Запись на прием">
      <div className="Checkout">
        <FilterPanel
          form={form}
          setForm={setForm}
          activeCityId={activeCityId}
          setActiveCityId={setActiveCityId}
        />
        <DoctorsPanel
          doctors={doctors}
          setDoctors={setDoctors}
          schedule={schedule}
        />
        {!isEmpty(dates) && !isEmpty(doctors) && !isEmpty(cityCats) ? (
          <Calendar
            schedule={filteredSchedule}
            dates={dates}
            doctors={doctors}
            cityCats={cityCats}
            activeCityId={activeCityId}
            type={type}
          />
        ) : (
          <p>Выберите дату</p>
        )}
      </div>
    </RouteComponent>
  )
}

class DatesForm {
  constructor() {
    this.date = {
      field: {
        fieldId: 'date',
        fieldType: 'dateRange',
        value: {
          from: addDays(startOfWeek(startOfToday()), 1),
          to: endOfWeek(startOfToday()),
        },
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default Checkout
