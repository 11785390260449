import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Newslist from './Newslist/Newslist'
import NewsManager from './NewsManager/NewsManager'
// import NewsManager from './NewsManager/NewsManager'

function News() {
  return (
    <Switch>
      <Route path={[`/news`]} exact render={() => <Newslist />} />
      <Route
        path={[`/news/add`, `/news/:id`]}
        exact
        render={() => <NewsManager closeLink={`/news`} />}
      />
    </Switch>
  )
}

export default News
