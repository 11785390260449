import React, { useState, useEffect } from 'react'
import StatusMessage, {
  useStatusMessage,
} from '../../../ui/StatusMessage/StatusMessage'
import { createForm } from '../../../utils/newforms/createForm'
import ServiceForm from './serviceObject'
import getDoc from '../../../utils/db/getDoc'
import isFormValid from '../../../utils/newforms/validation/isFormValid'
import getFormValues from '../../../utils/newforms/getFormValues'
import { updateDoc } from '../../../utils/db/updateDoc'
import addDoc from '../../../utils/db/addDoc'
import PopUp from '../../../ui/PopUp/PopUp'
import FormRender from '../../../utils/newforms/render/FormRender'
import { toNumber } from 'lodash'

function ServiceManager({ categoryId, serviceId, nextPos, onClose }) {
  const [form, setForm] = useState(null)
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  useEffect(() => {
    if (!serviceId) {
      setForm(
        createForm({
          formData: { categoryId },
          formPattern: new ServiceForm(nextPos),
        })
      )
    } else {
      getDoc({ path: `settings/price/services`, docId: serviceId }).then(
        (data) =>
          setForm(
            createForm({
              formData: data,
              formPattern: new ServiceForm(),
            })
          )
      )
    }
  }, [])

  const onSubmit = () => {
    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля',
        closeAfter: 3000,
      })
    }
    setIsLoading(true)

    const data = getFormValues({ form })
    const values = {
      ...data,
      pos: toNumber(data.pos),
    }

    let dbFunc
    if (serviceId) {
      dbFunc = updateDoc({
        path: `settings/price/services`,
        docId: serviceId,
        data: values,
      })
    } else {
      dbFunc = addDoc({ path: `settings/price/services`, data: values })
    }

    dbFunc
      .then(() => onClose())
      .catch((error) => {
        console.log(
          '🚀 ~ file: ServiceManager.jsx:77 ~ onSubmit ~ error:',
          error
        )
      })
      .finally(() => setIsLoading(false))
  }

  return (
    <PopUp
      show
      close={onClose}
      title={serviceId ? 'Редактировать услугу' : 'Создать услугу'}
      submitButtons
      onSubmit={onSubmit}
      onCancel={onClose}
      isLoading={isLoading}
    >
      <div className="CategoryPrice-PopUp-Container">
        {statusMessage.show && (
          <StatusMessage
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <FormRender
          sections={[
            { fields: ['title', 'price', 'schedule', 'duration', 'pos'] },
          ]}
          form={form}
          setForm={setForm}
          errors={showErrors}
        />
      </div>
    </PopUp>
  )
}

export default ServiceManager
